import React, { useState } from "react";
import {
	Box,
	Typography,
	useTheme,
	Button,
	useMediaQuery,
} from "@mui/material";
import Tooltip from "components/Tooltip";
import {
	CustomDesc,
	CustomMenuItem,
	CustomSelect,
	CustomTypography,
} from "./Common";

import {
	importSubscriberModalplatforms,
	importSubscriberModalchannels,
} from "constants/dashboardOptions.js";

const Compliance = ({ 
	setComplianceOption1: setCompliantService, 
	setComplianceOption2: setCollectionTool, 
	setOptionalImageUrl, 
	handleUploadFile 
}) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light";
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));

	// Local State
	const [service, setService] = useState("");
	const [tool, setTool] = useState("");
	const [uploadedImage, setUploadedImage] = useState(null);
	const [uploadError, setUploadError] = useState("");

	// Handle image upload
	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		console.log('FILE TYPE:',file.type);
		if (file) {
			setUploadError("");
			try {
				// Get the presigned URL for the image
				const presignedUrl = await handleUploadFile(file, "image");
				console.log(presignedUrl);
				const finalUrl = presignedUrl.split('?')[0]; // Extract the URL without query params
				setUploadedImage(finalUrl); // Update local state
				setOptionalImageUrl(finalUrl); // Notify parent component
			} catch (error) {
				console.error("Error uploading image:", error);
				setUploadError("An error occurred during the upload. Please try again.");
			}
		}
	};

	return (
		<Box>
			<Box mb="20px">
				<Box mb={"8px"}>
					<CustomTypography>SMS compliance requirements</CustomTypography>
				</Box>
				<CustomDesc theme={theme}>
					Tell us about how you collected your SMS subscribers.
				</CustomDesc>
			</Box>
			<Box mb={"20px"}>
				<Box display={"flex"} gap={"4px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Service (required)
					</Typography>
					<Tooltip text={""} lightMode={lightMode} />
				</Box>
				<Box width={belowSmall ? "100%" : "66%"}>
					<CustomSelect
						placeholder="Select a service"
						value={service}
						onChange={(event) => {
							setService(event.target.value);
							setCompliantService(event.target.value); // Pass value to parent
						}}
					>
						{importSubscriberModalplatforms?.map((item, index) => (
							<CustomMenuItem value={item} key={index}>
								{item}
							</CustomMenuItem>
						))}
					</CustomSelect>
				</Box>
			</Box>
			<Box mb={"20px"}>
				<Box display={"flex"} gap={"4px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Subscriber collection tool (required)
					</Typography>{" "}
					<Tooltip text={""} lightMode={lightMode} />
				</Box>
				<Box width={belowSmall ? "100%" : "66%"}>
					<CustomSelect
						placeholder="Select a tool"
						value={tool}
						onChange={(event) => {
							setTool(event.target.value);
							setCollectionTool(event.target.value); // Pass value to parent
						}}
					>
						{importSubscriberModalchannels?.map((item, index) => (
							<CustomMenuItem value={item} key={index}>
								{item}
							</CustomMenuItem>
						))}
					</CustomSelect>
				</Box>
			</Box>
			<Box mb={"20px"}>
				<Box display={"flex"} gap={"4px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Screenshot of your collection form (optional)
					</Typography>{" "}
					<Tooltip text={""} lightMode={lightMode} />
				</Box>
				<Box width={belowSmall ? "100%" : "66%"}>
					<Button
						component="label"
						fullWidth
						sx={{
							padding: "11px 20px",
							border: "1px dashed rgba(0, 129, 255, 0.40)",
							backgroundColor: lightMode ? "#FFF" : "rgba(255, 255, 255, 0.05)",
							marginRight: "8px",
						}}
						variant="outlined"
					>
						<img src="/icons/add.svg" alt="Upload Icon" />
						<Typography
							ml="8px"
							fontSize="14px"
							fontWeight="600"
							color="#0081FF"
							lineHeight="normal"
						>
							Upload Image
						</Typography>
						<input 
							type="file"
							accept="image/*"
							hidden
							onChange={(event)=>handleImageUpload(event)}
						/>
					</Button>
					{uploadedImage && (
						<Typography mt="8px" color="green">
							Image uploaded successfully!
						</Typography>
					)}
					{uploadError && (
						<Typography mt="8px" color="error">
							{uploadError}
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Compliance;
