import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeading from "components/Typography/PageHeading";
import TabSelection from "./component/TabSelection";
import Account from "./component/Account";
import Company from "./component/Company";
import Messaging from "./component/Messaging";
import User from "./component/User";
import PrimaryButton from "components/Buttons/PrimaryButton";
import AddUserModal from "components/Modal/Settings/AddUserModal";
import Discount from "./component/Discount";
import CreateDiscountModal from "components/Modal/Settings/CreateDiscountModal";
import Billing from "./component/Billing";
import { useLocation } from "react-router-dom";
import { txtCartFetch } from "utilifyFunctions";
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function Index() {
	const [sectionTab, setSectionTab] = useState(0);
	const [settingsObject, setSettingsObject] = useState({});
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [addUserModal, setAddUserModal] = useState(false);
	const [createDiscountModal, setCreateDiscountModal] = useState(false);
	const location = useLocation();
	const [currentSKU, setCurrentSKU] = useState({});
	const [currentCycle,setCurrentCycle] = useState("");
	const [skus, setSkus] = useState([]);
	async function getSettings(){
		console.log('getSettings');
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/settings");
		console.log('after')
		setSettingsObject(response);
	}

	async function getPlan() {
		const planResponse = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/plan");
		setCurrentSKU(planResponse.sku);
		setCurrentCycle(planResponse.activeCycle);
		return planResponse; 
	}

	async function getSKUs(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/sku");
		console.log('SKUS');
		setSkus(response);
	}

	async function handleDiscountChange(code, value){
		let body = {
			code: code, 
			value: value
		}
		Mixpanel.track_button_action(PAGES.SETTINGS, BUTTONS.CREATE_NEW_CODE);
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/discountChange", body);
		setCreateDiscountModal(false);
		getSettings();
	}

	async function saveSettings(){
		Mixpanel.track_button_action(PAGES.SETTINGS, BUTTONS.SAVE_SETTINGS);
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/settings/save", settingsObject);
		console.log(response);
		alert("Settings Saved!");
	}

	useEffect(() => {
		getSettings();
		getPlan();
		getSKUs();
	}, []);

	function setChildValues(fieldName, value){
		settingsObject[fieldName] = value;
		console.log(settingsObject);
	}
	
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		const tabValue = queryParams.get("tab");

		if (tabValue) {
			setSectionTab(tabValue);
		}else{
			setSectionTab('account')
		}
	}, [location]);
	return (
		// <Box paddingX={{ lg: 10, xl: 22, xs: 4 }} paddingY={2}>
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={belowSmall ? "baseline" : "center"}
				>
					<PageHeading mb="24px">Settings</PageHeading>
					<Box maxWidth={"159px"} mb={belowSmall ? "10px" : "-25px"}>
						{sectionTab === "account" && (
							<PrimaryButton padding="12px 37px" onClick={saveSettings}>Save Settings</PrimaryButton>
						)}
						{sectionTab === "company" && (
							<PrimaryButton fullWidth onClick={saveSettings}>Save Settings</PrimaryButton>
						)}
						{sectionTab === "messaging" && (
							<PrimaryButton fullWidth onClick={saveSettings}>Save Settings</PrimaryButton>
						)}
						{sectionTab === "discounts" && (
							<PrimaryButton
								fullWidth
								onClick={() => setCreateDiscountModal(true)}
							>
								Create New Code
							</PrimaryButton>
						)}
						{sectionTab === "users" && (
							<PrimaryButton fullWidth onClick={() => setAddUserModal(true)}>
								Add User
							</PrimaryButton>
						)}
					</Box>
				</Box>
				<TabSelection tab={sectionTab} setTab={setSectionTab} />
				{sectionTab === "account" && <Account settings={settingsObject} setSettings={setChildValues}/>}
				{sectionTab === "company" && <Company settings={settingsObject} setSettings={setChildValues}/>}
				{sectionTab === "messaging" && <Messaging settings={settingsObject} setSettings={setChildValues} getSettings={getSettings}/>}
				{sectionTab === "discounts" && <Discount settings={settingsObject}/>}
				{sectionTab === "users" && <User />}
				{sectionTab === "billing" && <Billing settings={settingsObject} currentSKU={currentSKU} period={currentCycle}
														skus={skus}/>}
			</Grid>
			<AddUserModal
				open={addUserModal}
				handleClose={() => setAddUserModal(false)}
			/>
			<CreateDiscountModal
				open={createDiscountModal}
				handleClose={() => setCreateDiscountModal(false)}
				handleDiscountChange = {handleDiscountChange}
			/>
		</Grid>
		// </Box>
	);
}