import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { CustomDesc, CustomTypography } from "./Common";
import { TextInput } from "components/Inputs";

const CSV = ({ communicationEmail, setCommunicationEmail }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<Box>
			<Box mb="24px">
				<Box mb={"8px"}>
					<CustomTypography>You’re all set to import!</CustomTypography>
				</Box>
				<CustomDesc theme={theme}>
					After you import, your subscribers will be available for campaigns and other SMS automations. 
				</CustomDesc>
			</Box>
			<Box
				bgcolor={
					mode === "light" ? "rgb(0, 13, 49,0.05)" : "rgb(255, 255, 255,0.05)"
				}
				borderRadius={"4px"}
				p={"16px"}
				mb={"20px"}
			>
				<Box mb={"16px"}>
					<CustomDesc theme={theme} mb="8px" lineHeight={"normal"}>
						Email address
					</CustomDesc>
					<Box
						bgcolor={theme?.palette?.card?.background}
						width={"249px"}
						borderRadius={"4px"}
						border={`1px solid ${theme?.palette?.card?.border}`}
					>
						<TextInput
							color={theme?.palette?.pageHeading}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
							placeholder="Enter your email address"
							name="title"
							value={communicationEmail}
							onChange={(event) => setCommunicationEmail(event.target.value)}
						/>
					</Box>
				</Box>
				<Box mb={"16px"}>
					<CustomDesc theme={theme} mb="8px" lineHeight={"normal"}>
						List Name
					</CustomDesc>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
					>
						Default
					</Typography>
				</Box>
				<Box mb={"16px"}>
					<CustomDesc theme={theme} mb="8px" lineHeight={"normal"}>
						Type of subscribers
					</CustomDesc>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
					>
						SMS only
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default CSV;