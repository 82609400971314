import { MenuItem, Select, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

export const CustomSelect = ({ children, placeholder, ...props }) => {
	const theme = useTheme();
	return (
		<Select
			sx={{
				"& .MuiSvgIcon-root": {
					color: "#C1C1C1",
					"&:focus": {
						outline: "none",
					},
				},
				"& .MuiInputBase-root": {
					border: `1px solid ${theme?.palette?.card?.border} !important`,
				},
				"& .MuiSelect-select .notranslate::after": placeholder
					? {
							content: `"${placeholder}"`,
							// opacity: 0.42,
							fontSize:'14px',
							color: "#808698",
							fontWeight: "500",
					}
					: {},
			}}
			IconComponent={GridExpandMoreIcon}
			style={{
				width: "100%",
				height: "40px",
				border: `1px solid ${theme?.palette?.card?.border} !important`,
			}}
			onChange={(e) => {
				console.info(e.target.value);
			}}
			placeholder="xtxux"
			defaultValue={"Select a list"}
			// displayEmpty
			{...props}
		>
			{children}
		</Select>
	);
};

export const CustomTypography = ({ children, theme }) => {
	return (
		<Typography
			color={theme?.palette?.pageHeading}
			fontSize={"16px"}
			fontWeight={600}
			lineHeight={"normal"}
			mb={1}
		>
			{children}
		</Typography>
	);
};

export const CustomDesc = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme?.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"20px"}
			sx={{
				opacity: "0.5",
			}}
		{...props}>
		{children}
		</Typography>
	);
};

export const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
		return (
			<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
				color: "#808698",
				fontWeight: 400,
				fontSize: "14px",
			}}
			{...props}>
			{children}
			</MenuItem>
	);
};
