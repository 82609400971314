import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import FeaturedAppsCard from "../../../components/Card/Integration/FeaturedAppsCard";
import PageHeading from "components/Typography/PageHeading";

export default function IntegrationApps() {
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;

	//const [isConnectModalOpen, setIsConnectModalOpen] = React.useState(false);

	const integrationAppsMock = [
		{
			name: "CartHook Post Purchase Offers",
			description:
				"Build post-purchase upsells | Accept with 1-click | Boost AOV",
			icon: "/img/integration/carthook.webp",
			isConnected: false,
			isAvailable: true,
			url:"https://apps.shopify.com/post-purchase-offers-carthook"
		},

		{
			name: "Vajro ‑ Mobile App Builder",
			description:
				"Get your mobile app ready in less than 60 minutes",
			icon: "/img/integration/vajro.png",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/vajro"
		},
		{
			name: "Snowball: Affiliate Marketing",
			description:
				"Automated Affiliate Programs, Referral Programs, & Ambassadors",
			icon: "/img/integration/snowball.webp",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/social-snowball"
		},
		{
			name: "Monster Cart Upsell+Free Gifts",
			description:
				"Slide Cart With Upsells + Free Gift With Purchase To Boost AOV",
			icon: "/img/integration/monster.webp",
			action: "Add App",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/monster-upsells"
		},
		{
			name: "Triple Whale Analytics",
			description: "Find the exact data points to grow your brand",
			icon: "/img/integration/triple.webp",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/triplewhale-1"
		},
		{
			name: "Wiser Product Recommendations",
			description:
				"For Personalized Recommendations & Post Purchase Upsell Offers",
			icon: "/img/integration/wiser.webp",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/recommended-products-wiser"
		},
		{
			name: "Recharge Subscriptions",
			description:
				" Maximize revenue with subscriptions, bundles & memberships",
			icon: "/img/integration/recharge.svg",
			isConnected: false,
			isAvailable: true,
			url:"https://apps.shopify.com/subscription-payments"
		},
		{
			name: "PushOwl: Web Push",
			description: "Recover abandoned carts and market better with web push",
			icon: "/img/integration/pushowl.webp",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/pushowl"
		},
		{
			name: "KNO Post Purchase Surveys",
			description: "Surveys for attribution, customer insights, and feedback",
			icon: "/img/integration/kno.webp",
			action: "Add App",
			isConnected: false,
			isAvailable: false,
			url:"https://apps.shopify.com/knocommerce"
		},
		{
			name: "Alia: Popup Alternative",
			description:
				"Reward customer education and convert more new store visitors",
			icon: "/img/integration/alia-1.png",
			isConnected: false,
			action: "Add App",
			url:"https://apps.shopify.com/alia"
		},
		{
			name: "TurboBoost: Pagespeed Booster",
			description: "Page Speed, SEO and Image optimizer to boost traffic & sales",
			icon: "/img/integration/turbo.png",
			action: "Add App",
			isConnected: false,
			url:"https://apps.shopify.com/turboboost"
		},
	
		{
			name: "Getsitecontrol Emails & Popups",
			description:
				"Email marketing: automations, newsletters, popups including email pop up windows",
			icon: "/img/integration/site-control.svg",
			action: "Add App",
			isConnected: false,
			isAvailable: true,
			url:"https://apps.shopify.com/getsitecontrol"
		},
	];
	return (
		<Box>
			<PageHeading mb={2} mt={2}>
				Integrations
			</PageHeading>
			<Box>
				<Grid
					mb={2.5}
					container
					gap={{ xs: 1.5, lg: 0 }}
					flexWrap={"wrap"}
					justifyContent={"space-between"}
				>
					{integrationAppsMock?.map((item, index) => {
						return (
							<Grid
								key={index}
								container
								item
								xs={12}
								sm={12}
								lg={3.9}
								flexDirection={"row"}
								sx={{
									padding: "16px",
									borderRadius: "12px",
									border:
										mode === "light"
											? "1px solid #E8EBF3"
											: "1px solid #1E202D",
									background: getMode.palette?.card?.background,
									marginBottom: 2,
								}}
							>
								<FeaturedAppsCard
									name={item?.name}
									description={item?.description}
									icon={item?.icon}
									action={item?.action}
									mode={mode}
									isConnected={item?.isConnected}
									url={item?.url}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Box>
			{/* <Connect
				mode={mode}
				open={isConnectModalOpen}
				handleClose={() => setIsConnectModalOpen(false)}
			/> */}
		</Box>
	);
}
