import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import { CustomDesc, CustomTypography } from "./Common";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["CSV"];


const Upload = ({ setFileUrl, handleUploadFile }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const [file, setFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadError, setUploadError] = useState(null);

	const handleChange = async (file) => {
		console.log(file.type);
		setUploadError(null);
		if (file.size > 10 * 1024 * 1024) {
			setUploadError("File size exceeds the 10 MB limit. Please upload a smaller file.");
			return;
		}
		setFile(file);
		setIsUploading(true);
		try {
			const presignedUrl = await handleUploadFile(file);

			// Pass the final file URL to the parent component
			const finalUrl = presignedUrl.split('?')[0]; // Extract the URL without query params
			setFileUrl(finalUrl);

			setIsUploading(false);
		} catch (error) {
			console.error("File upload failed:", error);
			setUploadError("File upload failed. Please try again.");
			setIsUploading(false);
		}
	};

	return (
		<Box>
			<Box mb="24px">
				<Box mb={"8px"}>
					<CustomTypography>Prepare your file and upload</CustomTypography>
				</Box>
				<CustomDesc theme={theme} mb="24px">
					Add your subscribers to the CSV template and upload it here.
				</CustomDesc>
				<Box maxWidth={'454px'}>
					<FileUploader
						handleChange={handleChange}
						name="file"
						types={fileTypes}
					>
						<Box
							padding={"20px"}
							display={"flex"}
							justifyContent={"center"}
							alignContent={"center"}
							textAlign={"center"}
							sx={{
								backgroundColor: mode === 'light' ? '#FFF' : "rgba(255, 255, 255, 0.05)",
								borderRadius: '4px'
							}}
							border="1px dashed rgba(0, 129, 255, 0.40);"
						>
							<Box>
								<Box>
									<img
										src="/img/file.svg"
										style={{
											width: "32px",
											height: "32px",
										}}
										alt="File Upload Icon"
									/>
								</Box>
								<Typography
									mb={"9px"}
									width={"276px"}
									color={theme?.palette?.pageHeding}
									fontSize={14}
									fontWeight={500}
								>
									Drop your CSV here or{" "}
									<span style={{ color: "#0081FF" }}>
										select a file from your computer
									</span>
								</Typography>
								<CustomDesc theme={theme}>
									Note: Maximum file size is 10 MB
								</CustomDesc>
							</Box>
						</Box>
					</FileUploader>
					{isUploading && (
						<Typography color="primary" fontSize={12} mt="8px">
							Uploading file, please wait...
						</Typography>
					)}
					{uploadError && (
						<Typography color="error" fontSize={12} mt="8px">
							{uploadError}
						</Typography>
					)}
					<Box display={"flex"} alignItems={"flex-start"} mt={"18px"}>
						<Checkbox
							size="small"
							defaultChecked
							sx={{ padding: "0", marginRight: "8px" }}
						/>
						<CustomDesc theme={theme} fontSize="12px">
							I certify that these subscribers were collected through a
							compliant opt-in mechanism in accordance with applicable laws and
							regulations, and that all subscribers (opt-out) have been removed.
						</CustomDesc>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Upload;
