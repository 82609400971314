import React, { useState } from "react";
import { Table } from "antd";
import { Box, Typography, styled, useTheme } from "@mui/material";
import { MenuItem, MenuList, ListItemText } from "@mui/material";
import TableHeading from "templates/Campaigns/components/TableHeader";
import { Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const CampaignsTable = ({ rows = [], page = "mainPage" }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const iconStyle = {
		transform: `rotate(${90}deg)`,
		transition: "transform 0.3s ease-in-out",
	};

	const columns = [
		{
			dataIndex: "campaignName",
			key: "campaignName",
			title: (
				<CustomTypography lightMode={lightMode} width={"200px"}>
					Campaign Name
				</CustomTypography>
			),

			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "sentOn",
			key: "sentOn",
			title: (
				<CustomTypography
					lightMode={lightMode}
					// sx={{
					// 	maxWidth: "50px",
					// }}
				>
					Sent On
				</CustomTypography>
			),
			sortable: false,

			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					sx={{
						maxWidth: "110px",
					}}
				>
					{params}
				</Typography>
			),
		},
		{
			key: "status",
			dataIndex: "status",
			title: (
				<CustomTypography
					width={"50px"}
					lightMode={lightMode}
					// sx={{
					// 	maxWidth: "200px",
					// }}
				>
					Status
				</CustomTypography>
			),
			width: "50px",
			render: (params) => showStatus(params),
		},
		{
			dataIndex: "sent",
			key: "sent",
			title: (
				<CustomTypography lightMode={lightMode} width={"70px"}>
					Sent
				</CustomTypography>
			),
			width: "50px",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					maxWidth={"70px"}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "replyRate",
			key: "replyRate",
			title: (
				<CustomTypography lightMode={lightMode} width={"70px"}>
					Reply Rate
				</CustomTypography>
			),
			sortable: false,
			width: "50px",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					maxWidth={"70px"}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "ctr",
			key: "ctr",
			title: <CustomTypography lightMode={lightMode}>CTR</CustomTypography>,
			sortable: false,
			width: "50px",
			maxWidth: "50px",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "revenue",
			key: "revenue",
			title: <CustomTypography lightMode={lightMode}>Revenue</CustomTypography>,
			sortable: false,
			width: "10%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "roi",
			key: "roi",
			title: <CustomTypography lightMode={lightMode}>ROI</CustomTypography>,
			sortable: false,
			width: "10%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params} &nbsp;&nbsp;&nbsp;
					<Dropdown
						overlay={<ActionMenu />}
						placement="bottomRight"
						trigger={["click"]}
					>
						<MoreOutlined style={iconStyle} />
					</Dropdown>
				</Typography>
			),
		},
	];

	const showStatus = (value) => {
		return mapStatus[value];
	};

	const mapStatus = {
		Sent: (
			<span className={`campaigns_status ${lightMode ? "Sent" : "Sent_Dark"}`}>
				Sent
			</span>
		),
		Draft: (
			<span
				className={`campaigns_status ${lightMode ? "Draft" : "Draft_Dark"}`}
			>
				Draft
			</span>
		),
		Scheduled: (
			<span
				className={`campaigns_status ${
					lightMode ? "Scheduled" : "Scheduled_Dark"
				}`}
			>
				Scheduled
			</span>
		),
		Sending: (
			<span
				className={`campaigns_status ${lightMode ? "Sending" : "Sending_Dark"}`}
			>
				Sending
			</span>
		),
		Error: (
			<span
				className={`campaigns_status ${lightMode ? "Error" : "Error_Dark"}`}
			>
				Error
			</span>
		),
	};

	const [currentPage, setCurrentPage] = useState(1);

	return (
		<>
			<div
				className="Campaigns-table"
				style={{
					background: theme.palette?.card?.background,
					borderTopLeftRadius: "12px",
					borderTopRightRadius: "12px",
					padding: 0,
					margin: 0,
				}}
			>
				<TableHeading theme={theme} />
				<CustomTable
					columns={columns}
					dataSource={rows}
					page={page}
					pagination={{
						showQuickJumper: false,
						total: rows.length,
						current: currentPage,
						defaultCurrent: 1,
						visible: false,
						defaultPageSize: 20,
						position: ["bottomCenter"],
						showTotal: (total) => (
							<Typography
								color={lightMode ? "#000D31" : "#FFF"}
							>{`${currentPage}/${Math.ceil(total / 20)}`}</Typography>
						),
						onChange: (page) => setCurrentPage(page),
					}}
					rowClassName="No-hover"
					// className="conversation-table-new"
				/>
			</div>
		</>
	);
};

export default CampaignsTable;

const CustomTable = styled(Table)(({ theme }) => ({
	"& .ant-table-thead .ant-table-cell": {
		backgroundColor: theme.palette?.card?.background,
		padding: "10px  0  10px 20px !important",
		minWidth: "100px",
	},
	"& .ant-table-thead th:first-child": {
		"border-start-start-radius": "0px !important",
	},
	"& .ant-table-thead th:last-child": {
		"border-start-end-radius": "0px !important",
	},

	"& .ant-table  .ant-table-content": {
		overflow: "auto",
		height: "auto",
	},
	"& .ant-table  table": {
		width: "100%",
		borderRadius: "0px",
	},
	// "& .No-hover .ant-table-cell-row-hover": {
	// 	background:
	// 		theme.palette?.mode === "light" ? "`} !important" : "#0F111B !important",
	// },

	"& .ant-table": {
		PointerEvent: "none",
		borderTopRightRadius: "0px !important" ,
		borderTopLeftRadius:  "0px !important",
		borderRadius: "0px",

		borderTop: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderBottomRightRadius: "0px !important",
		borderBottomLeftRadius: "0px !important",
		borderRight: `1px solid ${theme.palette?.card?.border}`,
		borderLeft: `1px solid ${theme.palette?.card?.border}`,
		borderBottom: "none",
	},
	"& .ant-table-thead >tr>th ": {
		borderBottom: `1px solid ${theme.palette?.card?.border}`,
	},
	"& .ant-table-tbody >tr >td": {
		borderBottom: `1px solid ${theme.palette?.card?.border}`,
		padding: "15px  0  17px 20px !important",
		backgroundColor: theme.palette?.card?.background,
	},
	"& .ant-table-thead >tr>th ::before": {
		backgroundColor: "transparent !important",
	},
	"& .ant-table-thead .ant-table-cell::before": {
		display: "none",
	},
	"& .ant-pagination": {
		borderLeft: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderRight: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderBottom: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-pagination-item": {
		display: "none !important",
	},
	"& .ant-pagination-total-text": {
		marginTop: "5px",
	},
	"& .ant-pagination-prev": {
		marginRight: "0px !important",
		"margin-inline-end": "0px !important",
		borderTopLeftRadius: "8px !important",
		borderTopRightRadius: "0px !important",
		borderBottomLeftRadius: "8px !important",
		borderBottomRightRadius: "0px !important",
		border: `1px solid ${theme.palette?.card?.border}`,
	},
	"& .ant-pagination-next": {
		borderTopLeftRadius: "0px !important",
		borderTopRightRadius: "8px !important",
		borderBottomLeftRadius: "0px !important",
		borderBottomRightRadius: "8px !important",
		border: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-pagination-next::hover": {
		background: "tranparent",
	},
	"& .ant-pagination-item-link": {
		color: `${theme.palette?.pageHeading} !important`,
	},
}));

const CustomTypography = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			fontSize={12}
			fontWeight={500}
			lineHeight={"normal"}
			sx={{
				opacity: "0.5 !important",
			}}
			{...props}
			color={lightMode ? "#000D31" : "#FFF"}
		>
			{children}
		</Typography>
	);
};

const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode === "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};

const ActionMenu = () => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={theme.palette?.card?.border}
			borderRadius={" 0px 0px 8px 8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem key={"1"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Edit</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"2"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Duplicate</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"3"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme} color="#FF465C">
							Delete
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
