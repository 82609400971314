import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Divider,
} from "@mui/material";
import Compliance from "./Compliance";
import SelectList from "./SelectList";
import Upload from "./Upload";
import CSV from "./CSV";
import { CustomDesc } from "./Common";
import Imported from "./Imported";
import { txtCartFetch } from "utilifyFunctions";

export default function ImportSubscribers({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light";
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));

	// Centralized state
	const [tab, setTab] = useState(0);
	const [listId, setListId] = useState(null);
	const [listType, setListType] = useState("New List");
	const [compliantService, setCompliantService] = useState("");
	const [collectionTool, setCollectionTool] = useState("");
	const [optionalImageUrl, setOptionalImageUrl] = useState("");
	const [fileUrl, setFileUrl] = useState("");
	const [imported, setImported] = useState(false);
	const [communicationEmail, setCommunicationEmail] = useState("");
	const [fileName, setFileName] = useState("");


	const handleUploadFile = async (file) => {
		try {
			const requestBody = {
				fileType: file.type,
				shopId: localStorage.getItem("shopId"),
				listId: listId,
			};
			console.log("Request Body: ", requestBody);
	
			const response = await txtCartFetch(
				"POST",
				`${process.env.REACT_APP_BACKEND_DOMAIN}/dashboard/list/presigned-url`,
				requestBody,
				true
			);
			console.log("Presigned URL Response: ", response);
			console.log(file.type);
			if (response.success) {
				const presignedUrl = response.data.presignedUrl;
				const savedListId = response.data.listId;
	
				// Update parent state with the new listId
				if (!listId) setListId(savedListId);
	
				// Upload file to S3 using the pre-signed URL
				try {
					const uploadResponse = await fetch(presignedUrl, {
						method: "PUT",
						headers: {
							"Content-Type": file.type,
						},
						body: file,
					});
					console.log(await uploadResponse.text());
					if (!uploadResponse.ok) {
						throw new Error(`Upload failed: ${uploadResponse.statusText}`);
					}
	
					console.log("File upload successful");
					return presignedUrl; // Return URL if needed
				} catch (error) {
					console.error("Error uploading file to S3:", error);
				}
			}
		} catch (error) {
			console.error("Error during file upload process:", error);
		}
	};	
	
	async function uploadSubscriberList() {
		try {
			// Make API call
			const shopId = localStorage.getItem("shopId");
			const requestBody = {
				listId: listId,
				fileUrl: fileUrl,
				fileName: fileName,
				shopId: shopId,
				compliantService: compliantService,
				collectionTool: collectionTool,
				optionalImageUrl: optionalImageUrl,
				communicationEmail: communicationEmail
			};
			console.log(requestBody)
			const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/list/finalize-upload", requestBody, true);
			console.log(response)
			if (!response.success) {
				setImported(false);
			} else {
				setImported(true);
			}
		} catch (error) {
			console.error("Error uploading subscriber list:", error);
		}
	}

	return (
		<Modal
			open={open}
			id="Import"
			aria-labelledby="Import"
			aria-describedby="Import"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				{!imported ? (
					<>
						<Box p="20px">
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								mb={"8px"}
							>
								<Typography
									color={theme.palette?.pageHeading}
									fontSize={"20px"}
									fontWeight={600}
									lineHeight={"normal"}
								>
									Import Subscribers
								</Typography>
								<img
									src="/icons/cut.svg"
									alt="cut"
									onClick={() => {
										setTab(0);
										handleClose();
									}}
									id="pointerCursor"
									style={{
										marginTop: "-10px",
									}}
								/>
							</Box>
							<Box>
								<CustomDesc theme={theme}>
									Import your existing SMS and email subscribers so you can use them in your campaigns.
								</CustomDesc>
							</Box>
						</Box>

						<Divider />
						<Box p="20px" display={"flex"} flexDirection={"column"}>
							<Box
								bgcolor={lightMode ? "#EFF7FF" : "#20212C"}
								display="flex"
								gap={belowSmall ? "5px" : "12px"}
								p={belowSmall ? "8px 17px" : "11px 17px"}
								width="100%"
								borderRadius="4px"
								mb="20px"
							>
								{TabOptions.map((item, index) => (
									<React.Fragment key={index}>
										<Box>
											<Typography
												fontSize={belowSmall ? "12px" : "14px"}
												fontWeight={600}
												lineHeight="normal"
												sx={{
													opacity: !tab === index && !lightMode && "0.7",
												}}
												color={
													tab === index
														? "#0081FF"
														: theme.palette.pageHeading
												}
											>
												{item}
											</Typography>
										</Box>
										{index < TabOptions.length - 1 && (
											<img src="/arrow/segment-right.svg" />
										)}
									</React.Fragment>
								))}
							</Box>

							{/* Render Tabs */}
							{tab === 0 && <SelectList listType={listType} setListType={setListType} fileName={fileName} setFileName={setFileName}/>}
							{tab === 1 && (
								<Compliance
									setComplianceOption1={setCompliantService}
									setComplianceOption2={setCollectionTool}
									setOptionalImageUrl={setOptionalImageUrl}
									handleUploadFile={(file) => handleUploadFile(file)}
								/>
							)}
							{tab === 2 && (
								<Upload setFileUrl={setFileUrl} handleUploadFile={(file) => handleUploadFile(file)}/>
							)}
							{tab === 3 && (
								<CSV setCommunicationEmail={setCommunicationEmail} communicationEmail={communicationEmail}/>
							)}
							
							{/* Footer Buttons */}
							<Box
								display="flex"
								justifyContent={belowSmall ? "space-between" : "end"}
								height={"40px"}
								alignItems="center"
							>
								{/* Back Button */}
								{tab > 0 && tab < 3 && (
									<Button
										sx={{
											padding: "11px 20px",
											border: `1px solid ${theme.palette.card.border}`,
											backgroundColor: "transparent",
											marginRight: belowSmall ? "0px" : "8px",
										}}
										variant="outlined"
										onClick={() => setTab(tab - 1)}
									>
										<Typography
											fontSize="14px"
											fontWeight="600"
											color={theme.palette.pageHeading}
											lineHeight="normal"
										>
											Back
										</Typography>
									</Button>
								)}

								{/* Next/Complete Button */}
								<Button
									size="small"
									sx={{
										backgroundColor: "#0081FF",
										padding: "11px 20px",
										color: "#FFF",
										":hover": {
											color: lightMode ? "#0C0D17" : "#FFF",
										},
									}}
									variant="outlined"
									onClick={() => {
										if (tab === 3) {
											uploadSubscriberList();
										} else {
											setTab(tab + 1);
										}
									}}
								>
									<Typography fontSize="14px" fontWeight="600">
										{tab === 3 ? "Complete" : "Next"}
									</Typography>
								</Button>
							</Box>
						</Box>
					</>
				) : (
					<Imported
						setTab={setTab}
						handleClose={handleClose}
						setImported={() => setImported(false)}
					/>
				)}
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "703px",
				backgroundColor: theme?.palette?.card?.background,
				// padding: "20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				// height:'407px',
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
				maxHeight: "90vh",
			}}
		>
			{children}
		</Box>
	);
};

const TabOptions = ["Select List", "Compliance", "Upload", "Confirmation"];
