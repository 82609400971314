import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import PageHeading from "components/Typography/PageHeading";
import React, { useState, useEffect } from "react";
import Card from "./components/Card";
import {
	NewSubscriberSVG,
	SubscriberSVG,
	UnSubscriberSVG,
} from "resources/Icons/Analytics";
import GraphSection from "./components/GraphSection";
import TableSection from "./components/TableSection";
import Select from "components/Select/Select";
import { TimeSelection } from "resources/constant";
import { parseDateFilter } from "utilifyFunctions";
import { txtCartFetch } from "utilifyFunctions";
import { getPreviousPeriodFilter } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";
import { getDateChartTick } from "utilifyFunctions";
import ImportSubscribers from "components/Modal/ImportSubscribers";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function Subscribers() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [dateRange, setDateRange] = useState("last_days_30");
	const [subsciberGraphData, setSubscriberGraphData] = useState([]);
	const [totalSubscribers, setTotalSubscribers] = useState(null);
	const [newSubscribers, setNewSubscribers] = useState(null);
	const [newSubscribersPrevious, setNewSubscribersPrevious] = useState(null);
	const [newSubscriberDiff, setNewSubscriberDiff]= useState(null);
	const [unsubscribers, setUnsubscribers] = useState(null);
	const [unsubscribersPrevious, setUnsubscribersPrevious] = useState(null);
	const [unsubscribersDiff, setUnsubscribersDiff] = useState(null);
	const [ltv, setLtv] = useState(null);
	const [ltvPrevious, setLtvPrevious] = useState(null);
	const [ltvDiff, setLtvDiff] = useState(null);
	const [shoppers, setShoppers] = useState([]);
	const [openImportModal, setOpenImportModal] = useState(false);

	const handleDateChange = (label, value) => {
		setDateRange(value);
		setNewSubscriberDiff(null);
		setUnsubscribersDiff(null);
		setLtvDiff(null);
		setLtv(null);
		setNewSubscribers(null);
		setUnsubscribers(null);
		setTimeout(() => {
			loadMetrics(value);
		}, 500); 
	}

	const getOldestCart = async function(){
		let oldestCart = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/oldest");
		return oldestCart;
	}

	const loadSubscribers = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/count"+dateRange);
		setTotalSubscribers(response?.shoppers);
	}

	const getNewSubscribers = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/new"+dateRange);
		setNewSubscribers(response?.shoppers);
	}

	const getNewSubscribersPrevious = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/new"+dateRange);
		setNewSubscribersPrevious(response?.shoppers);
	}

	const getUnsubscribers = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/unsubscribe"+dateRange);
		setUnsubscribers(response?.shoppers);
	}

	const getUnsubscribersPrevious = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/unsubscribe"+dateRange);
		setUnsubscribersPrevious(response?.shoppers);
	}

	const getLTV = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/ltv"+dateRange);
		setLtv(response?.LTV);
	}

	const getLTVPrevious = async (dateRange) =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/ltv"+dateRange);
		setLtvPrevious(response?.LTV);
	}

	const getShoppers = async () =>{
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shoppers");
		for(let i = 0; i < response?.length; i++){
			response[i].name = response[i].first_name ? response[i].first_name: "";
			if(response[i].last_name){
				response[i].name += (" " + response[i].last_name);
			}
			if(response[i].name === " " || response[i].name === "" || response[i].name === null || response[i].name === undefined){
				response[i].name = "Unknown";
			}
			try{
				response[i].subscriberDate = new Date(response[i].createdAt).toLocaleDateString();
			} catch(err){
				console.log(err);
			}
		}
		setShoppers(response);
	}

	async function loadMetrics(filter){
		let dateRange = parseDateFilter(filter);
		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		getSubscribers(urlDateRange,filter);
		loadSubscribers(urlDateRange);
		getNewSubscribers(urlDateRange);
		getUnsubscribers(urlDateRange);
		getLTV(urlDateRange);
		if(filter !== "all_time"){
			let previousFilter = getPreviousPeriodFilter(filter);
			let previousDateRange = parseDateFilter(previousFilter);
			let previousURLDateRange = (dateRange ? "?start="+previousDateRange.start+"&end="+previousDateRange.end : "");
			getNewSubscribersPrevious(previousURLDateRange);
			getUnsubscribersPrevious(previousURLDateRange);
			getLTVPrevious(previousURLDateRange);
		} else{
			setNewSubscriberDiff(0);
			setUnsubscribersDiff(0);
		}
	}

	useEffect(() => {
		if(newSubscribers!= null && newSubscribersPrevious != null){
			setNewSubscriberDiff(newSubscribersPrevious > 0 ? (((newSubscribers - newSubscribersPrevious) / newSubscribersPrevious) * 100).toFixed(0) : newSubscribers > 0 ? 100 : 0);
			
		}
	}, [newSubscribers, newSubscribersPrevious]);

	useEffect(() => {
		if(unsubscribers!= null && unsubscribersPrevious != null){
			setUnsubscribersDiff(unsubscribersPrevious > 0 ? (((unsubscribers - unsubscribersPrevious) / unsubscribersPrevious) * 100).toFixed(0) : unsubscribers > 0 ? 100 : 0);
		}
	}, [unsubscribers, unsubscribersPrevious]);

	useEffect(() => {
		if(ltv!= null && ltvPrevious != null){
			setLtvDiff(ltvPrevious > 0 ? (((ltv - ltvPrevious) / ltvPrevious) * 100).toFixed(0) : ltv > 0 ? 100 : 0);
		}
	}, [ltv, ltvPrevious]);

	const checkAllTimeRange = function(oldestCart){
		let MS_IN_HOUR = 60 * 60 * 1000; 
		let MS_IN_DAY = 24 * MS_IN_HOUR;
		if(oldestCart){
			let today = new Date();
			let yesterday = addDays(today,-1);
			let oldestDate = new Date(oldestCart.lastMessage);

			let currentTime = Date.now();
			let diff = currentTime - oldestCart.lastMessage;

			if(today.getDate() === oldestDate.getDate() && today.getMonth() === oldestDate.getMonth() && today.getFullYear() === oldestDate.getFullYear()){
				return "today";
			}
			else if(yesterday.getDate() === oldestDate.getDate() && yesterday.getMonth() === oldestDate.getMonth() && yesterday.getFullYear() === oldestDate.getFullYear()){
				return "yesterday";
			}
			else if(diff < (7 * MS_IN_DAY)){
				return "last_days_7";
			}
			else if(diff < (30 * MS_IN_DAY)){
				return "last_days_30";
			}
			else{
				return "full";
			}
		} else{
			return("today");
		}
	}

	async function getSubscribers(dateRange,filter){
		if(filter === 'all_time'){
			let localOldestCart = await getOldestCart();
			filter = checkAllTimeRange(localOldestCart);
			dateRange = parseDateFilter(filter, localOldestCart);
			dateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		}

		let URL = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/date"+dateRange
		if(dateRange){
			URL += "&dateFilter="+filter;
		}

		const response = await txtCartFetch("GET",URL);
		for(let i = 0; i < response?.dataPoints?.length; i++){
			response.dataPoints[i].date = getDateChartTick(response.dataPoints[i].date,filter);
		}

		setSubscriberGraphData(response?.dataPoints);
	}

	useEffect(() => {
		loadMetrics("last_days_30");
	}, []);

	useEffect(() => {
		getShoppers();
	}, []);

	
	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
				>
				<PrimaryButton
					onClick={() => {
					setOpenImportModal(true);
					}}
				>
					Import
				</PrimaryButton>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={!isMobile ? "row" : "column"}
				>
					<PageHeading>Subscribers</PageHeading>
					<Select
						options={TimeSelection}
						handleSelectChange={handleDateChange}
						defaultValue="Last 30 Days"
					>
					</Select>
				</Box>
				<ImportSubscribers
					open={openImportModal}
					handleClose={() => setOpenImportModal(false)}
				/>
			</Grid>

			<Grid item xs={12} mb={"8px"}>
				<GraphSection data={subsciberGraphData} total={totalSubscribers}/>
			</Grid>

			<Grid item xs={12}>
				<Box
					sx={{
						display: "flex",
						gap: "24px",
						flexDirection: isMobile ? "column" : "row",
					}}
					mb={"8px"}
				>
					<Card
						title= "Subscriber LTV"
						value= {ltv?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						isIncrease= {ltvDiff >= 0}
						byValue= {ltvDiff}
						valueType='amount'
						icon= {<SubscriberSVG />}
					/>
					<Card
						title= "New Subscribers"
						value= {newSubscribers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						isIncrease = {newSubscriberDiff >= 0}
						valueType='number'
						byValue= {newSubscriberDiff?.toString().replace("-","")}
						icon= {<NewSubscriberSVG />}
						/>
					<Card
						title= "Unsubscribers"
						value= {unsubscribers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						isIncrease= {unsubscribersDiff >= 0}
						valueType='number'
						byValue= {unsubscribersDiff?.toString().replace("-","")}
						icon= {<UnSubscriberSVG />}
					/>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<TableSection  data={shoppers}/>
			</Grid>
		</Grid>
	);
}
