import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	CalendarSVG,
	LocationSVG,
	MailSVG,
	PhoneSVG,
} from "resources/Icons/Inbox/Index";
import RecoverStatusIcon from "../components/RecoveredStatus";
//import AddTagModal from "components/Modal/TagsModals/AddTagModal";
import { txtCartFetch } from "utilifyFunctions";
import { formatPhoneNumber, formatTimeFromHours } from "../components/commons";
import UnsubscribeCustomerModal from "components/Modal/UnsubscribeCustomerModal";
import { SkeletonText } from "./Contacts";


export default function CustomerDetails({ activeTab, setTab, isActive, setIsActive, bodyMarginTop }) {
	const theme = useTheme();
	const [isEditing, setEditing] = useState(false);
	const [editedText, setEditedText] = useState("");
	const [data, setData] = useState(null);
	const [conversationDetails, setConversationDetails] = useState({});

	const handleGetCustomerData = async () => {
		setData(null);
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/customer_details/" + isActive?.type + "/" + isActive?.id);
		setTimeout(() => {
			setData(response);
		}, [1000]);
	}

	const handleFormattingTimeSpanForCustomerDetails = (time) => {
		let timeSpan = formatTimeFromHours(time);
		if(!timeSpan) return timeSpan;

		const timeMap = {
			'm' : "minute",
			'h' : 'hour',
			'd'	: 'day',
			'mo': 'month',
			'y' : 'year'
		};
	
		// Extract the abbreviation from the timeSpan (e.g., "2d" -> "d")
		let unit = timeSpan.slice(-1);
		let value = timeSpan.slice(0, -1);
	
		// Convert the abbreviation to full word using the map, and return the full word format
		if (timeMap[unit]) {
			const unitWord = timeMap[unit] + (value === "1" ? '' : 's'); // Add 's' for plural
			return `${value} ${unitWord}`;
		} else {
			// Check for month
			unit = timeSpan.slice(-2);
			value = timeSpan.slice(0, -2);
			if(timeMap[unit]) {
				const unitWord = timeMap[unit] + (value === "1" ? '' : 's'); // Add 's' for plural
				return `${value} ${unitWord}`;
			}
		}
	
		return timeSpan; // Return as-is if there's no match (as a fallback)
	}

	useEffect(() => {
		if (isActive?.id) {
			handleGetCustomerData();
		}
	}, [isActive?.id]);

	const handleEditClick = () => {
		setEditing(true);
	};

	const handleCancelClick = () => {
		setEditing(false);
		setEditedText("");
	};

	const handleSaveClick = () => {
		setEditing(false);
		setEditedText("");
		if(!editedText){
			return
		}
		let tmp = { ...data };
		tmp?.notes?.unshift({ name: "Jason | Feb 10, 2023", note: editedText });
		setData({ ...tmp });
		// console.log("Saved:", editedText);
	};
	const handleDelete = (index) => {
		let tmp = { ...data };
		tmp?.notes?.splice(index, 1);
		setData({ ...tmp });
	};
	// const [tagModal, setTagModal] = useState(false);

	useEffect(() => {
		const getConversationDetails = async () => {
			setConversationDetails({});
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/conversation/details/" + isActive?.type + "/" + isActive?.id);
			setTimeout(() => {
				setConversationDetails(response)
			}, [1000]);
		}

		if (isActive?.id) {
			getConversationDetails();
		}
	}, [isActive?.id]);
	
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box
			width={isMobile ? "100%" : "30%"}
			bgcolor={theme?.palette?.card?.background}
			position={bodyMarginTop? "relative": "unset"}
			bottom={`${bodyMarginTop}px`}
			mt={`${bodyMarginTop}px`}
			borderLeft={`1px solid ${theme?.palette?.card?.border}`}
			display={
				isMobile ? (activeTab === "Customer" ? "block" : "none") : "block"
			}
		>
			<Box
				p={"18px 16px 16px 16px"}
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			>
				<PrimaryText fontSize="20px" fontWeight="600">
					Customer Details
				</PrimaryText>
			</Box>
			<Box
				p={"20px 15px"}
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			>
				<Box display={"flex"} justifyContent={"space-between"} mb={"4px"}>
					<PrimaryText fontSize="16px" fontWeight="600">
					{data?.name ?? <SkeletonText width={"150px"} />}
					</PrimaryText>
					{/* <SecondaryText
						color="#0081FF"
						id="pointerCursor"
						onClick={() => setTab("2")}
					>
						Manage
					</SecondaryText> */}

				</Box>
				<SecondaryText>{data?.type}</SecondaryText>
				<Box display={"flex"} gap={"15px"} flexDirection={"column"} mt={"17px"}>
					<Box display={"flex"} gap={"10px"}>
						<CalendarSVG /> 
						<PrimaryText>{data?.timeSpan? `Customer for about ${handleFormattingTimeSpanForCustomerDetails(data?.timeSpan)}` : <SkeletonText width={"150px"} />}</PrimaryText>
					</Box>
					<Box display={"flex"} gap={"10px"}>
					<MailSVG /> <PrimaryText>{data?.email === "" ? "Unknown" : data?.email ?? <SkeletonText width={"150px"} />}</PrimaryText>
						{data?.acceptEmail && data?.email && <Box
							bgcolor={"rgba(12, 209, 106, 0.10)"}
							display={"flex"}
							justifyContent={"center"}
							borderRadius={"2px"}
							p={"1px 14px"}
							alignContent={"center"}
							height={"20px"}
							alignItems={"center"}
						>
							<Typography
								fontSize={12}
								fontWeight={600}
								lineHeight={"normal"}
								color={"#00BE5B"}
							>
								Subscribed
							</Typography>
						</Box>}
					</Box>
					<Box display={"flex"} gap={"10px"}>
						<PhoneSVG /> <PrimaryText>
							{data?.number ? `${formatPhoneNumber(data?.number)}` : <SkeletonText width={"150px"} />}
						</PrimaryText>
						{data?.subscribed && !isActive?.unsubscribed ? <Box
							bgcolor={"rgba(12, 209, 106, 0.10)"}
							display={"flex"}
							justifyContent={"center"}
							borderRadius={"2px"}
							p={"1px 14px"}
							alignContent={"center"}
							height={"20px"}
							alignItems={"center"}
						>
							<Typography
								fontSize={12}
								fontWeight={600}
								lineHeight={"normal"}
								color={"#00BE5B"}
							>
								Subscribed
							</Typography>
						</Box> : data && <Box
							bgcolor={"rgba(255, 0, 76, 0.1)"}
							display={"flex"}
							justifyContent={"center"}
							borderRadius={"2px"}
							p={"1px 14px"}
							alignContent={"center"}
							height={"20px"}
							alignItems={"center"}
						>
							<Typography
								fontSize={12}
								fontWeight={600}
								lineHeight={"normal"}
								color={"rgb(255, 0, 76)"}
							>
								Unsubscribed
							</Typography>
						</Box>
						}
					</Box>
					<Box display={"flex"} gap={"10px"}>
						<LocationSVG /> <PrimaryText>
							{data?.location ?? <SkeletonText width={"100px"} />}
						</PrimaryText>
					</Box>
				</Box>
				<SecondaryText
					fontSize="12px"
					fontWeight="400"
					mt="6px"
					ml="31px"
					mb="20px"
				>
					{data?.localeTime ?? <SkeletonText width={"100px"} />}
				</SecondaryText>
				{/* <Box display={"flex"} gap={"10px"} mb={"13px"} alignItems={"center"} flexWrap={'wrap'}>
					<img src="/icons/tag.svg" />

					{data?.tag?.map((item, index) => {
						return (
							<Typography
								key={index}
								component={"span"}
								sx={{
									backgroundColor: "rgb(153, 99, 254,0.15)",
									padding: "6px",
									borderRadius: "4px",
								}}
								fontSize={12}
								fontWeight={550}
								lineHeight={"normal"}
								color={"#622BCA"}
							>
								{item}
							</Typography>
						);
					})}
					<SecondaryText
						color="#0081FF"
						id="pointerCursor"
						onClick={() => {
							setTagModal(true);
						}}
					>
						Add Tag
					</SecondaryText>
				</Box>
				<Box>
					{isEditing ? (
						<div>
							<TextArea
								autoSize={{minRows:3}}
								value={editedText}
								onChange={(e) => setEditedText(e.target.value)}
							/>
							<Box display={"flex"} justifyContent={"end"} mt={"11px"}>
								<Box display={"flex"} gap={"12px"}>
									<Typography
										id="pointerCursor"
										color={"#808698"}
										fontSize={"14px"}
										fontWeight={500}
										onClick={handleCancelClick}
										lineHeight={"normal"}
									>
										Cancel
									</Typography>
									<Typography
										color={"#0081FF"}
										fontSize={"14px"}
										fontWeight={500}
										lineHeight={"normal"}
										id="pointerCursor"
										onClick={handleSaveClick}
									>
										Save Note
									</Typography>
								</Box>
							</Box>
						</div>
					) : (
						<SecondaryText
							color="#0081FF"
							onClick={handleEditClick}
							id="pointerCursor"
						>
							Add Note
						</SecondaryText>
					)}
				</Box>
				<Box>
					{data?.notes?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<Box
									p={"15px 0px"}
									sx={{
										borderBottom:
											index < data?.notes?.length - 1
												? `1px solid ${theme?.palette?.card?.border}`
												: "",
									}}
								>
									<Box
										display={"flex"}
										justifyContent={"space-between"}
										mb={"8px"}
									>
										<PrimaryText>{item?.name}</PrimaryText>
										<SecondaryText
											color="#FF465C"
											onClick={() => handleDelete(index)}
											id="pointerCursor"
										>
											Delete
										</SecondaryText>
									</Box>
									<SecondaryText fontSize="12px" fontWeight="400">
										{item?.note}
									</SecondaryText>
								</Box>
							</React.Fragment>
						);
					})}
				</Box> */}
			</Box>
			<Box
				p={"20px 15px"}
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			>
				<PrimaryText fontSize="16px" fontWeight="600" mb="18px">
					CONVERSATION DETAILS
				</PrimaryText>
				<ConversationDetails conversationDetails={conversationDetails} isActive={isActive} setIsActive={setIsActive}/>
			</Box>
			{/* <AddTagModal
				open={tagModal}
				setUserData={setData}
				userData={data}
				// valueForSearch={valueForSearch}
				// setValueForSearch={setValueForSearch}
				handleClose={() => setTagModal(false)}
			/> */}
		</Box>
	);
}

const ConversationDetails = ({ conversationDetails, isActive, setIsActive }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const [unsubscribed, setUnsubscribed] = useState(isActive?.unsubscribed);
	const {
		id,
		topic,
		assignee,
		source,
		avgRespTime,
		numberOfOrders,
		moneySpent,
		numberOfChats,
		hasOptedOut,
	} = conversationDetails;

	const mapBadges = {
		awaiting_response: (
			<span
				className={`conversation_status ${
					lightMode ? "Awaiting_Response" : "Awaiting_Response_Dark"
				}`}
			>
				Awaiting Response
			</span>
		),
		customer_replied: (
			<span
				className={`conversation_status ${
					lightMode ? "Customer_Replied" : "Customer_Replied_Dark"
				}`}
			>
				Customer Replied
			</span>
		),
		discount_offered: (
			<span
				className={`conversation_status ${
					lightMode ? "Discount_Offered" : "Discount_Offered_Dark"
				}`}
			>
				Discount Offered
			</span>
		),
		ordered: (
			<span
				className={`conversation_status ${
					lightMode ? "Ordered" : "Ordered_Dark"
				}`}
			>
				Ordered
			</span>
		),
		cart_viewed: (
			<span
				className={`conversation_status ${
					lightMode ? "Cart_Viewed" : "Cart_Viewed_Dark"
				}`}
			>
				Cart Viewed
			</span>
		),
		cart_recovered: (
			<RecoverStatusIcon />
		)
	};

	const [unsubscribeCustomerModalOpen, setUnsubscribeCustomerModalOpen] = useState(false);
	
	function formatMoneySpent(moneySpent) {
		if (typeof moneySpent !== "string" || !moneySpent.startsWith("$")) {
			return moneySpent
		}
	
		// Remove the "$" sign temporarily for processing
		const numberPart = moneySpent.slice(1);
	
		// Split into integer and decimal parts
		const [integerPart, decimalPart] = numberPart.split(".");
	
		// Add commas to the integer part
		const formattedInteger = parseInt(integerPart, 10).toLocaleString();
	
		// Reassemble the formatted money with "$" sign, integer, and decimal parts
		const formattedMoney = `$${formattedInteger}.${decimalPart ? decimalPart.padEnd(2, '0') : "00"}`;
	
		return formattedMoney;
	}

	async function handleUnsubscribeConversation() {
		let requestBody = {
			shopperId: isActive?.shopperId
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/conversation/unsubscribe", requestBody, true);
		if(response.success){
			setUnsubscribed(true)  
			setIsActive({ ...isActive, unsubscribed: true });
			setUnsubscribeCustomerModalOpen(false)
		}else {
			console.log("Unsubscribe failed");
		}
	}

	return (
		<Box display={"flex"} gap={"21px"} flexDirection={"column"}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Box width={"50%"}>
					<SecondaryText>Topic</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{topic ?? <SkeletonText width={"100px"} />}</PrimaryText>
				</Box>
			</Box>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Box width={"50%"}>
					<SecondaryText>Status</SecondaryText>
				</Box>		
				<Box
					width={"50%"}
					textAlign={"end"}
					display={"flex"}
					justifyContent={"end"}
				>
					{mapBadges[conversationDetails?.badge] ?? <SkeletonText width={"100px"} />}
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Source</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{source ?? <SkeletonText width={"100px"} />}</PrimaryText>
				</Box>
			</Box>
			{/* <Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Team Mate Assigned</SecondaryText>
				</Box>
				<Box
					width={"50%"}
					textAlign={"end"}
					display={"flex"}
					gap={"5px"}
					justifyContent={"end"}
				>
					<PrimaryText>{assignee}</PrimaryText>
				</Box>
			</Box> */}

			{/* <Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Created</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{created}</PrimaryText>
				</Box>
			</Box> */}
			{/* <Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Average Response Time</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{avgRespTime}</PrimaryText>
				</Box>
			</Box> */}
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Number of Orders</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{numberOfOrders ?? <SkeletonText width={"100px"} />}</PrimaryText>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Total Money Spent</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{moneySpent ? formatMoneySpent(moneySpent) : <SkeletonText width={"100px"} /> }</PrimaryText>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Number of Messages</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{numberOfChats ?? <SkeletonText width={"100px"} />}</PrimaryText>
				</Box>
			</Box>
			{conversationDetails?.id && (
				<>
					<Button
						size="small"
						fullWidth
						sx={{
							padding: "11px 30px",
							border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
							color: mode === "light" ? "#FF465C" : "#FF465C",
						}}
						disabled={unsubscribed || hasOptedOut}
						variant="outlined"
						onClick={() => setUnsubscribeCustomerModalOpen(true)}
					>
						<Typography fontSize="14px" fontWeight="600">
							{unsubscribed || hasOptedOut ? "Unsubscribed" : "Unsubscribe"}
						</Typography>
					</Button>
					<UnsubscribeCustomerModal
						open={unsubscribeCustomerModalOpen}
						handleClose={() => setUnsubscribeCustomerModalOpen(false)}
						handleUnsubscribeConversation={handleUnsubscribeConversation}
						phoneNumber={isActive?.number}
					/>
				</>
				)}
		</Box>
	);
};
const PrimaryText = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Typography
			fontWeight={500}
			fontSize={14}
			color={theme?.palette?.pageHeading}
			lineHeight={"normal"}
			{...props}
		>
			{children}
		</Typography>
	);
};
const SecondaryText = ({ children, ...props }) => {
	return (
		<Typography
			fontWeight={500}
			fontSize={14}
			color={"#808698"}
			{...props}
			lineHeight={"normal"}
		>
			{children}
		</Typography>
	);
};
