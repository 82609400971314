/* eslint-disable no-unused-vars */
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Grid,
	MenuItem,
	RadioGroup,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";

import { DataContext } from "context/DataContext";
import { Switch } from "antd";
import { Add, BackArrow, DownArrow } from "resources/Icons/Arrows";
import { DeleteSVG, UploadSVG } from "resources/Icons";
import { TextInput, CustomSelect } from "components/Inputs";
import Tooltip from "components/Tooltip";
import CustomRadioButton from "components/Inputs/RadioButton";
import SecondaryButton from "components/Buttons/SecondaryButton";
import PageHeading from "components/Typography/PageHeading";
import TextMessage from "./components/Text-message";
import { useNavigate, useSearchParams } from "react-router-dom";
import PhoneInputField from "components/Inputs/PhoneInput";
import Conversation from "../components/Conversation";
import { conversationsTableMock, targetAudienceOptionMock } from "resources/constant";
import ConfirmModal from "components/Modal/Confirm";
import MessageTemplates from "components/Modal/MessageTemplates";
import CustomDatePicker from "components/Inputs/DatePicker";
import TextEditor from "./components/TextEditor";
import TargetAudience from "./components/TargetAudience";
import { txtCartFetch } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";
import SendMessageConfirmation from 'components/Modal/SendMessageConfirmation'
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function CreateCampaign() {
	const theme = useTheme();
	const navigate = useNavigate();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const [searchParams, setSearchParams] = useSearchParams();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowMedium = useMediaQuery(theme.breakpoints.down("md"));
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [smsCount, setSMSCount] = useState(1);
	const [campaignData, setCampaignData] = useState({
		date: "",
		title: "",
		description: "",
		img: "",
		category: "",
		previewText: "",
	});
	const [saving, setSaving] = useState(false);
	const [winningMetric, setWinningMetric] = useState("Click Rate");
	const [showABTesting, setShowABTesting] = useState(false);
	const [showScheduleSection, setShowScheduleSection] = useState(false);
	const [smartSending, setSmartSending] = useState(false);
	const [excludeAudience, setExcludeAudience] = useState(false);
	const [browseCampaignModal, setBrowseCampaignModal] = useState(false);
	const [sendMessageModal, setSendMessageModal] = useState(false)
	const [intervalValue, setIntervalValue] = useState("");
	const [confirm, setConfirm] = useState(false);
	const [startDate, setStartDate] = useState(addDays(new Date(),1));
	const [targetAudience, setTargetAudience] = useState(null);
	const [codeType, setCodeType] = useState("unique");
	const [discountType, setDiscountType] = useState("fixed_amount");
	const [discountValue, setDiscountValue] = useState(null);
	const [discountUrl, setDiscountUrl] = useState(null);
	const [discountPath, setDiscountPath] = useState(null);
	const [isEmbedLink, setIsEmbedLink] = useState(false);
	const [maxTexts, setMaxTexts] = useState(null);
	const [file,setFile] = useState(null);
	const [campaignId, setCampaignId] = useState(null);
	const [campaignTemplateId, setCampaignTemplateId] = useState(null);
	const [isGSM, setIsGSM] = useState(false);
	const [formattedDate, setFormattedDate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [priceRules, setPriceRules] = useState([]);
	const [value, setValue] = useState(
		`<span class="mention" data-denotation-char="" data-id="StoreName-${Date.now()}" data-value="{StoreName}"><span contenteditable="false">{StoreName}</span></span> : Hey <span class="mention" data-denotation-char="" data-id="FirstName-${Date.now()}" data-value="{FirstName}"><span contenteditable="false">{FirstName}</span></span> did you hear about our amazing discounts? Don't miss out, visit <span class="mention" data-denotation-char="" data-id="StoreUrl-${Date.now()}" data-value="{StoreUrl}"><span contenteditable="false">{StoreUrl}</span></span>`
	);
	const [testPhone, setTestPhone] = useState("");
	const [segmentSizes, setSegmentSizes] = useState(null);
	const[addDiscountModal,setAddDiscountModal] = useState(false);
	const [textValue, setTextValue] = useState("{StoreName} : Hey {FirstName} did you hear about our amazing discounts? Don't miss out, visit {StoreUrl}");
	const handleDataChange = (e) => {
		setCampaignData({ ...campaignData, [e.target.name]: e.target.value });
	};
	const confirmAgree = () => {
		saveDraft();
	};
	const confirmDisagree = () => {
		setConfirm(false);
	};
	const [image, setImage] = useState(null);
	const [priceRule, setPriceRule] = useState(null);
	const [codeConfirmed, setCodeConfirmed] = useState(false);

	const getSegmentSizes = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/campaignSegmentSize");
		setSegmentSizes(response);
	}

	const getPriceRules = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/getAllPriceRules");
		setPriceRules(response?.price_rules);
		console.log("Price Rules",response);
	}

	const saveCampaign = async function(){
		Mixpanel.track_button_action(PAGES.CREATE_SMS_CAMPAIGN, BUTTONS.REVIEW_CAMPAIGN);
		const res = await packageCampaign();	
		if(res?.id){
			navigate("/campaigns/campaigns-overview?cid="+res.id);
		}
	}

	const saveDraft = async function(){
		Mixpanel.track_button_action(PAGES.CREATE_SMS_CAMPAIGN, BUTTONS.SAVE_DRAFT);
		const res = await packageCampaign();	
		if(res?.id){
			navigate("/campaigns");
		}
	}

	const sendTest = async function(){
		Mixpanel.track_button_action(PAGES.CREATE_SMS_CAMPAIGN, BUTTONS.SEND_TEST);
		if(!testPhone){
			alert("No phone number on file. Please contact support to update your phone number.");
		}
		const res = await packageCampaign();	
		setSendMessageModal(false)
		if(res?.id){
			console.log("saved: ", res.id);
			let body = {
				id: res.id
			}
			const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/campaign/send/test",body);
			if(response?.success){
				alert("Test message sent!")
			} else{
				alert("There was an issue sending your test message. Please try again or contact support");
			}
		}
	}

	const handleTemplateSelect = function(template){
		console.log("setting template");
		console.log(template);
		setCampaignTemplateId(template._id);
		handleTemplate(template.text);
		Mixpanel.track_button_action(PAGES.CREATE_SMS_CAMPAIGN, BUTTONS.SELECT_TEMPLATE, {template:template._id});
	}

	const handleDateChange = function(date){
		console.log("CHANING DATE");
		console.log(date);
		let dd = String(date.getDate()).padStart(2,'0');
		let mm = String(date.getMonth() + 1).padStart(2,'0');
		let yyyy = String(date.getFullYear());
		setFormattedDate( mm + '/' + dd + '/' + yyyy);
		setStartDate(date);
		console.log(formattedDate);
	}

	const packageCampaign = async function(){
		let localMaxTexts = maxTexts;
		if(!saving){
			setSaving(true);
			if(segmentSizes === null){
				alert("Segment calculation in progress. Try again.");
				setSaving(false);
				return;
			}
			else if(!textValue){
				alert("Cannot send an empty message");
				setSaving(false);
				return;
			}
			else if(!campaignData.title){
				alert("Campaign Name is required");
				setSaving(false);
				return;
			}
			else if(!targetAudience){
				alert("A target audience is required");
				setSaving(false);
				return;
			}
			else if(showScheduleSection && !startDate){
				alert("A date is required if the campaign is scheduled for later");
				setSaving(false);
				return;
			}
			else if(localMaxTexts === 0){
				alert("Target Audience must contain at least one recipient to create a one time campaign. If you have any questions, please contact support.");
				setSaving(false);
				return;
			}
			else if(!codeConfirmed && textValue.includes("{DiscountCode")){
				alert("Your message requires a discount code to be configured.");
				setAddDiscountModal(true);
				setSaving(false);
				return;
			}
			else if(!textValue.includes("{DiscountCode}") && textValue.includes("{DiscountValue}")){
				console.log("inside");
				alert("You have included a {DiscountValue}, but not included a {DiscountCode}. Please add a {DiscountCode} or remove the {DiscountValue}");
				setSaving(false);
				return;
			}

			if(localMaxTexts === null || localMaxTexts === undefined){

				if(segmentSizes[targetAudience]){
					localMaxTexts = segmentSizes[targetAudience];
					setMaxTexts(segmentSizes[targetAudience]);
				} else{
					alert("There was an issue identifying your segment. Please try again or contact support");
					setSaving(false);
					return;
				}
			}


			let formData = new FormData(); 
			formData.append('text_message', textValue);
			formData.append('campaign_name', campaignData?.title);
			formData.append("scheduleDate", formattedDate);
			formData.append("target_audience", targetAudience);
			formData.append("maxTexts",localMaxTexts);
			formData.append("imageFileName", file ? file.name : null);
			formData.append("campPhoto", file);
			formData.append("htmlMessage",value);
			formData.append("isScheduled",showScheduleSection);
			formData.append("smsCount", smsCount);
			formData.append("isGSM", isGSM);
			formData.append("templateId", campaignTemplateId);
			formData.append("code_type",codeType);
			formData.append("add_discount", textValue.includes("{DiscountCode"));
			formData.append("discount_code",priceRule);
			formData.append("discount_type",discountType);
			formData.append("discountURL",discountUrl);
			formData.append("embed", isEmbedLink);
			let localDiscountPath = discountPath;
			if(discountPath && discountPath[0] === "/"){
				localDiscountPath = discountPath.slice(1);
			}
			formData.append("linkPath",localDiscountPath);

			if(codeType === "same"){
				let val = "";
				for(let i = 0; i < priceRules.length; i++){
					if(priceRules[i].id === priceRule){
						val = priceRules[i]?.value?.replace("-","");
					}
				}
				formData.append("discount_value",val);
			} else{
				formData.append("discount_value",discountValue);
			}

			try{
				const response = await fetch(process.env.REACT_APP_BACKEND_DOMAIN + "/saveCampaign", {
					method: 'POST',
					mode: 'cors',
					credentials: 'include',
					body: formData,
					headers: {
						campId: campaignId
					}
				});
				const res = await response.json();
				setSaving(false);
				return res;
			} catch(err){
				setSaving(false);
				alert("There was an issue saving your campaign. Please try again or contact support.");
			}
		}
	}

	const loadCampaign = async function(){
		console.log("GET CID");
		let cid = searchParams.get("cid");
		let tid = searchParams.get("tid");
		setCampaignId(cid);
		setCampaignTemplateId(tid);
		console.log(cid);
		if(cid){
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/getCampaignData?id="+cid);
			if(!response?.htmlMessage){
				alert("The campaign you are attempting to load is not compatible with TxtCart Campaigns 3.0. Please close this alert to start a new campaign.");
				navigate("/campaigns/create-campaigns");
			} else{
				console.log("Unique:" + response.uniqueCode);
				setPriceRule(response.discountCode);
				setCodeType(response.uniqueCode ? "unique" : "same");
				setDiscountValue(response.discountAmount);
				setCampaignData({ ...campaignData, title: response.name});
				setDiscountUrl(response.discountLink);
				setDiscountPath(response.linkPath);
				setIsEmbedLink(response.embed);
				setDiscountType(response.discountType);
				setTargetAudience(response.filter);
				setTextValue(response.message);
				setValue(response.htmlMessage);
				setCodeConfirmed(response.discountIncluded)
				if(response.imgFileName && response.imgFileName !== "null"){
					let imgURL = "https://txtcartcampimagebucket.s3.us-east-2.amazonaws.com/" + response.image;
					setImage(`<img src="${imgURL}" alt="Image" width='200px' />`)
				}
			}
		} else if(tid){
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/campaign/template?tid="+tid);
			handleTemplate(response?.template?.text);
			loadDomain();
		} else{
			loadDomain();
		}
	}

	const handleTemplate = function(templateText){
		setTextValue(templateText);
		let htmlValue = templateText;
		htmlValue = templateText.replaceAll(/\n/g,"<br>");
		htmlValue = htmlValue.replaceAll(/{FirstName}/g, `<span class="mention" data-denotation-char="" data-id="FirstName-${Date.now()}" data-value="{FirstName}"><span contenteditable="false">{FirstName}</span></span>`);
		htmlValue = htmlValue.replaceAll(/{LastName}/g, `<span class="mention" data-denotation-char="" data-id="LastName-${Date.now()}" data-value="{LastName}"><span contenteditable="false">{LastName}</span></span>`);
		htmlValue = htmlValue.replaceAll(/{StoreName}/g, `<span class="mention" data-denotation-char="" data-id="StoreName-${Date.now()}" data-value="{StoreName}"><span contenteditable="false">{StoreName}</span></span>`);
		htmlValue = htmlValue.replaceAll(/{StoreUrl}/g,`<span class="mention" data-denotation-char="" data-id="StoreUrl-${Date.now()}" data-value="{StoreUrl}"><span contenteditable="false">{StoreUrl}</span></span>`);
		htmlValue = htmlValue.replaceAll(/{DiscountValue}/g,`<span class="mention" data-denotation-char="" data-id="DiscountValue-${Date.now()}" data-value="{DiscountValue}"><span contenteditable="false">{DiscountValue}</span></span>`);
		htmlValue = htmlValue.replaceAll(/{DiscountCode}/g,`<span class="mention" data-denotation-char="" data-id="DiscountCode-${Date.now()}" data-value="{DiscountCode}"><span contenteditable="false">{DiscountCode}</span></span>`);
		setValue(htmlValue);
	}

	const loadTemplates = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/campaign/template/all");
		setTemplates(response?.templates);
	}

	const loadDomain = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/domain");
		setDiscountUrl(response?.domain);
	}

	const loadPhone = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/owner_phone");
		setTestPhone(response?.phone);
	}


	useEffect(() => {
		loadCampaign();
		getSegmentSizes();
		loadTemplates(); 
		getPriceRules();
		loadPhone();
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} lg={9} md={12}>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item xs={12}>
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							alignItems={"center"}
							position={"relative"}
							ml={isSmall ? "35px" : "0px"}
						>
							<Box
								position={"absolute"}
								id={"pointerCursor"}
								onClick={() => navigate(-1)}
								sx={{
									left: "-30px",
								}}
							>
								<BackArrow />
							</Box>
							<PageHeading>Create SMS Campaign</PageHeading>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<ChildContainer>
							<CustomHeading theme={theme} mb={2}>
								Campaign name
							</CustomHeading>
							<TextInput
								placeholder="Enter a campaign name"
								onChange={handleDataChange}
								value={campaignData?.title}
								name="title"
							/>
						</ChildContainer>
					</Grid>
					<Grid item xs={12} className="create-campaign-target">
						<ChildContainer>
							<TargetAudience
								setExcludeAudience={setExcludeAudience}
								excludeAudience={excludeAudience}
								setTargetAudience={setTargetAudience}
								setMaxTexts={setMaxTexts}
								segments={segmentSizes}
								value={targetAudience}
							/>
						</ChildContainer>
					</Grid>
					<Grid item xs={12}>
						<ChildContainer>
							<TextEditor
								setImage={setImage}
								image={image}
								value={value}
								setValue={setValue}
								setTextValue={setTextValue}
								setBrowseCampaignModal={setBrowseCampaignModal}
								heading={showABTesting ? "Text Message (A)" : "Text Message"}
								setFile={setFile}
								setSMSCount={setSMSCount}
								smsCount={smsCount}
								setIsGSM={setIsGSM}
								priceRules={priceRules}
								setPriceRule={setPriceRule}
								codeType={codeType}
								setCodeType={setCodeType}
								discountType={discountType}
								setDiscountType={setDiscountType}
								setDiscountValue={setDiscountValue}
								discountValue={discountValue}
								discountUrl={discountUrl}
								setDiscountUrl={setDiscountUrl}
								setDiscountPath={setDiscountPath}
								discountPath={discountPath}
								setIsEmbedLink={setIsEmbedLink}
								isEmbedLink={isEmbedLink}
								priceRule={priceRule}
								setAddDiscountModal={setAddDiscountModal}
								addDiscountModal={addDiscountModal}
								setCodeConfirmed={setCodeConfirmed}
							/>
						</ChildContainer>
					</Grid>
					<Grid item xs={12}>
						<ChildContainer p={0}>
							{/* <Box p={"20px"}>
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									alignItems={"center"}
								>
									<Box display={"flex"} alignItems={"center"} gap={"10px"}>
										<CustomHeading
											theme={theme}
											fontSize={isSmall ? "18px" : "20px"}
										>
											A/B testing
										</CustomHeading>
										<Box mt={"8px"}>
											{" "}
											<Tooltip
												text={
													"Split test messaging and images across your campaign"
												}
												lightMode={lightMode}
												belowTablet={belowTablet}
											/>
										</Box>
										<GetFatureButton />
									</Box>
									<Box>
										<Switch
											value={showABTesting}
											onChange={() => setShowABTesting(!showABTesting)}
										/>
									</Box>
								</Box>

								<Box
									className={`ABtesting-section ${
										showABTesting ? "visible" : "hidden"
									}`}
								>
									<Box mt={"30px"}>
										<TextEditor
											setBrowseCampaignModal={setBrowseCampaignModal}
											heading="Text Message (B)"
										/>
									</Box>
								</Box>
							</Box> */}

							<Box
								className={`ABtesting-section ${
									showABTesting ? "visible" : "hidden"
								}`}
							>
								<Divider />
								<Box p={isSmall ? "12px" : "20px"}>
									<CustomHeading theme={theme} mb="20px">
										Content Test Settings
									</CustomHeading>
									<CustomSubHeading theme={theme} mb={"16px"}>
										Winning Metric
									</CustomSubHeading>
									<Box mb={"24px"}>
										<RadioGroup
											value={winningMetric}
											onChange={(e) => {
												setWinningMetric(e?.target?.value);
											}}
										>
											<Box display={"flex"} alignItems={"baseline"} mb={"12px"}>
												<Box>
													<CustomRadioButton value="Click Rate" />
												</Box>
												<Box>
													<CustomSubHeading
														theme={theme}
														fontWeight={500}
														mb={"4px"}
													>
														Click Rate
													</CustomSubHeading>
													<Typography
														color={"#808698"}
														fontSize={12}
														fontWeight={500}
													>
														The variation with the highest click rate wins
													</Typography>
												</Box>
											</Box>
											<Box display={"flex"} alignItems={"baseline"} mb={"12px"}>
												<Box>
													<CustomRadioButton value="Placed Order Rate" />
												</Box>
												<Box>
													<CustomSubHeading
														theme={theme}
														fontWeight={500}
														mb={"4px"}
													>
														Placed Order Rate
													</CustomSubHeading>
													<Typography
														color={"#808698"}
														fontSize={12}
														fontWeight={500}
													>
														The variation with the highest placed order rate
														wins
													</Typography>
												</Box>
											</Box>
											<Box display={"flex"} alignItems={"baseline"}>
												<Box>
													<CustomRadioButton value="Numbers of Clicks" />
												</Box>
												<Box>
													<CustomSubHeading
														theme={theme}
														fontWeight={500}
														mb={"4px"}
													>
														Numbers of Clicks
													</CustomSubHeading>
													<Typography
														color={"#808698"}
														fontSize={12}
														fontWeight={500}
													>
														The variation with the highest placed order rate
														wins
													</Typography>
												</Box>
											</Box>
										</RadioGroup>
									</Box>
									<CustomSubHeading theme={theme} mb={"16px"}>
										Test Size
									</CustomSubHeading>
									<Box
										display={"flex"}
										gap={"8px"}
										mb={"15px"}
										flexDirection={isSmall ? "column" : "row"}
									>
										<Box
											sx={{
												backgroundColor: lightMode
													? "rgba(217, 236, 255, 0.50);"
													: "rgba(12, 132, 254, 0.10)",
												borderRadius: "4px 0px 0px 4px",
												border: `1px solid rgba(0, 129, 255, 0.15);`,
											}}
											py={"12px"}
											textAlign={"center"}
											width={"100%"}
										>
											<Typography
												fontSize={16}
												fontWeight={600}
												color={"#0081FF"}
											>
												A
											</Typography>
											<Typography
												fontSize={12}
												fontWeight={500}
												color={"#808698"}
											>
												50% (4,590 people)
											</Typography>
										</Box>
										<Box
											sx={{
												backgroundColor: lightMode
													? "rgba(217, 236, 255, 0.50);"
													: "rgba(12, 132, 254, 0.10)",
												borderRadius: "0px 4px 4px 0px",
												border: `1px solid rgba(0, 129, 255, 0.15);`,
											}}
											py={"12px"}
											textAlign={"center"}
											width={"100%"}
										>
											<Typography
												fontSize={16}
												fontWeight={600}
												color={"#0081FF"}
											>
												B
											</Typography>
											<Typography
												fontSize={12}
												fontWeight={500}
												color={"#808698"}
											>
												50% (4,590 people)
											</Typography>
										</Box>
									</Box>
									<Typography fontSize={12} fontWeight={500} color={"#808698"}>
										If you choose 100%, the campaign will send to everyone at
										the scheduled sent time and you will have the ability to
										send in the recipient’s local timezone.
									</Typography>
								</Box>
							</Box>
						</ChildContainer>
					</Grid>

					<Grid item xs={12}>
						<ChildContainer>
							<Box
								display={"flex"}
								justifyContent={"space-between"}
								alignItems={"center"}
							>
								<Box display={"flex"} gap={"10px"} alignItems={"center"}>
									<CustomHeading theme={theme}>
										Schedule for later
									</CustomHeading>
									<Box mt={"8px"}>
										{" "}
										<Tooltip
											text={
												"Choose a date to schedule the campaign in advance"
											}
											lightMode={lightMode}
											belowTablet={belowTablet}
										/>
									</Box>
								</Box>
								<Switch
									value={showScheduleSection}
									onChange={() => setShowScheduleSection(!showScheduleSection)}
								/>
							</Box>

							<Box
								className={`Schedule-for-later-section ${
									showScheduleSection ? "visible" : "hidden"
								}`}
							>
								<Box
									display={"flex"}
									gap={"14px"}
									mb={"8px"}
									mt={"20px"}
									flexDirection={isSmall ? "column" : "row"}
								>
									<Box width={"100%"}>
										<Typography
											fontSize={14}
											fontWeight={500}
											color={theme.palette?.pageHeading}
											mb={"8px"}
										>
											Date
										</Typography>
										<CustomDatePicker
											selected={startDate}
											onChange={(date) => handleDateChange(date)}
											minDate={addDays(new Date(),1)}
										>
											<Box className={"btn-container"}>
												{/* <Button
													size={"small"}
													sx={{
														padding: "11px 30px",
														border:
															mode === "light"
																? "1px solid #E8EBF3"
																: "1px solid #1E202D",
														color: theme.palette?.pageHeading,
														marginRight: "10px",
														borderRadius: "8px",
														backgroundColor: theme.palette?.card?.background,
													}}
													variant="outlined"
												>
													<Typography fontSize={"14px"} fontWeight={"600"}>
														Reset
													</Typography>
												</Button> */}
												{/* <Button
													size={"small"}
													sx={{
														height: "40px",
														backgroundColor: "#0081FF",
														padding: "11px 31px",
														color: mode === "light" ? "#FFF" : "#FFF",
														":hover": {
															color: mode === "light" ? "#0C0D17" : "#FFF",
														},
														fontSize: "14px",
														fontWeight: "600",
														borderRadius: "8px",
													}}
													variant="outlined"
												>
													Done
												</Button> */}
											</Box>
										</CustomDatePicker>
									</Box>
									{/* <Box width={"100%"}>
										<Typography
											fontSize={14}
											fontWeight={500}
											color={theme.palette?.pageHeading}
											mb={"8px"}
										>
											Time
										</Typography>
										<CustomSelect
											// placeholder="Country"

											onChange={(e) => setIntervalValue(e?.target?.value)}
											// getOptionLabel={customLabel}
											// getOptionValue={(value) => value}
											// value={ intervalValue}
											suffixIcon={<DownArrow />}
											defaultValue={"05:00 PM"}
											// renderValue={(value, selectedOptions) => (
											// 	<span>
											// 		<VerifiedUserOutlined /> {value}
											// 	</span>
											// )}
										>
											{timeInterval?.map((item, index) => {
												return (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												);
											})}
										</CustomSelect>
									</Box> */}
								</Box>
								{/* <Box mb={"21px"}>
									<Typography
										fontSize={12}
										fontWeight={500}
										color={"#808698"}
										lineHeight={"normal"}
									>
										Your default timezone is set to: America/New_York
									</Typography>
								</Box> */}
								{/* <Box
									display={"flex"}
									alignItems={isSmall ? "flex-start" : "center"}
									flexDirection={isSmall ? "column" : "row"}
								>
									<Box display={"flex"} mb={isSmall ? "12px" : "0"}>
										<Checkbox
											size="small"
											defaultChecked
											sx={{ padding: "0" }}
										/>
										<Typography
											fontSize={14}
											fontWeight={500}
											color={lightMode ? "#323232" : "#FFF"}
											lineHeight={"normal"}
											ml={"8px"}
										>
											Send based on subscriber’s timezone.
										</Typography>
									</Box>
									<Box ml={isSmall ? "0" : "8px"}>
										<GetFatureButton />
									</Box>
								</Box> */}
							</Box>
						</ChildContainer>
					</Grid>
					{/* <Grid item xs={12}>
						<ChildContainer>
							<CustomHeading theme={theme} mb={"16px"}>
								Smart Sending
							</CustomHeading>

							<RadioGroup
								value={smartSending}
								onChange={(e) => {
									setSmartSending(e?.target?.value);
								}}
							>
								<Box
									mb={"13px"}
									display={"flex"}
									alignItems={isSmall ? "flex-start" : "center"}
								>
									<CustomRadioButton value={true} />
									<CustomSubHeading theme={theme} fontWeight={500}>
										Enable Smart Sending: Subscribers who received a text in the
										last <b>16 hours</b> will not receive your campaign
									</CustomSubHeading>
								</Box>
								<Box
									display={"flex"}
									alignItems={isSmall ? "flex-start" : "center"}
								>
									<CustomRadioButton value={false} />
									<CustomSubHeading theme={theme} fontWeight={500}>
										Disable Smart Sending: All subscribers will receive your
										campaign
									</CustomSubHeading>
								</Box>
							</RadioGroup>
						</ChildContainer>
					</Grid> */}
					{/* <Grid item xs={12}>
						<ChildContainer>
							<Box>
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									alignItems={"start"}
								>
									<CustomHeading theme={theme} mb={"9px"}>
										Follow up with customers who haven’t ordered
									</CustomHeading>
									<Box>
										<Switch defaultChecked onChange={() => console.log("")} />
									</Box>
								</Box>
								<Typography
									fontSize={"12px"}
									fontWeight={500}
									lineHeight={"normal"}
									color={"#808698"}
									mb={1}
								>
									Purchase trends show that customers are most likely to buy 24
									hours after receiving broadcast. Follow up in one day with
									customers who have received this broadcast but have not
									purchased.
								</Typography>
							</Box>
						</ChildContainer>
					</Grid> */}
					{/* <Grid item xs={12}>
						<ChildContainer>
							<Box display={"flex"} gap={"8px"} alignItems={"center"}>
								<CustomHeading theme={theme} mb={"16px"}>
									Get campaign highlights via SMS
								</CustomHeading>
								<Box mt={"-10px"}>
									{" "}
									<Tooltip
										text={
											"We will send you stats about your campaign after the attribution window has closed"
										}
										lightMode={lightMode}
										belowTablet={belowTablet}
									/>
								</Box>
							</Box>
							<PhoneInputField />
						</ChildContainer>
					</Grid> */}
					<Grid item xs={12}>
						<Box
							display={"flex"}
							justifyContent={isSmall ? "space-between" : "end"}
						>
							<Box>
								<Button
									size={"small"}
									sx={{
										padding: "11px 30px",
										border:
											mode === "light"
												? "1px solid #E8EBF3"
												: "1px solid #1E202D",
										color: theme.palette?.pageHeading,
										marginRight: "10px",
										backgroundColor: theme.palette?.card?.background,
									}}
									variant="outlined"
									onClick={() => setConfirm(true)}
								>
									<Typography fontSize={"14px"} fontWeight={"600"}>
										Save Draft
									</Typography>
								</Button>
								<SecondaryButton
									disabled={saving}
									onClick={() => saveCampaign()}
								>
									Review Campaign
								</SecondaryButton>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				lg={3}
				md={12}
				sx={{
					position: "relative",
				}}
			>
				<Conversation value={value} image={image} setSendMessageModal={setSendMessageModal}/>
			</Grid>
			<ConfirmModal
				open={confirm}
				confirmAgree={confirmAgree}
				confirmDisagree={confirmDisagree}
				text="Save Draft?"
				confirmButtonText="Save" 
				cancelButtonText="Cancel"
			/>
			<MessageTemplates
				open={browseCampaignModal}
				setOpen={setBrowseCampaignModal}
				handleClose={() => setBrowseCampaignModal(false)}
				templates={templates}
				setCampaignTemplate={handleTemplateSelect}
			/>
			<SendMessageConfirmation  open={sendMessageModal} handleClose={()=>setSendMessageModal(false)} sendTest={sendTest} testPhone={testPhone}/>
		</Grid>
	);
}

export const CustomHeading = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"20px"}
			fontWeight={600}
			lineHeight={"normal"}
			{...props}
		>
			{children}
		</Typography>
	);
};
export const CustomSubHeading = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={600}
			lineHeight={"normal"}
			{...props}
		>
			{children}
		</Typography>
	);
};

const GetFatureButton = () => {
	const { setShowBillingModal } = useContext(DataContext);
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<span
			id="pointerCursor"
			style={{
				padding: "2px 10px",
				borderRadius: "4px",
				height: "22px",
				color: "#9963FE",
				fontSize: "14px",
				fontWeight: "600",
				lineHeight: "normal",
				backgroundColor: mode === "light" ? "#E3DCF2" : "#211A3A",
			}}
			onClick={() => {
				setShowBillingModal(true);
			}}
		>
			<span style={{ marginRight: "5px" }}>
				<UploadSVG />
			</span>
			Get Feature
		</span>
	);
};

const ChildContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box
			p={belowSmall ? "12px" : "20px"}
			sx={{
				borderRadius: "12px",
				border: `1px solid ${theme?.palette?.card?.border}`,
				backgroundColor: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</Box>
	);
};

export const AICopyAssistantButton = ({ children, lightMode, ...props }) => {
	const theme = useTheme();
	return (
		<Button
			size={"small"}
			sx={{
				height: "40px",
				backgroundColor: theme?.palette?.card?.background,
				border: "none",
				padding: "0",
				// color: mode === "light" ? "#FFF" : "#FFF",
				":hover": {
					border: "none",
				},
				fontSize: "14px",
				fontWeight: "600",
			}}
			variant="outlined"
			{...props}
		>
			{children}
		</Button>
	);
};

const timeInterval = [
	"12:00 AM",
	"12:30 AM",
	"01:00 AM",
	"01:30 AM",
	"02:00 AM",
	"02:30 AM",
	"03:00 AM",
	"03:30 AM",
	"04:00 AM",
	"04:30 AM",
	"05:00 AM",
	"05:30 AM",
	"06:00 AM",
	"06:30 AM",
	"07:00 AM",
	"07:30 AM",
	"08:00 AM",
	"08:30 AM",
	"09:00 AM",
	"09:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"01:00 PM",
	"01:30 PM",
	"02:00 PM",
	"02:30 PM",
	"03:00 PM",
	"03:30 PM",
	"04:00 PM",
	"04:30 PM",
	"05:00 PM",
	"05:30 PM",
	"06:00 PM",
	"06:30 PM",
	"07:00 PM",
	"07:30 PM",
	"08:00 PM",
	"08:30 PM",
	"09:00 PM",
	"09:30 PM",
	"10:00 PM",
	"10:30 PM",
	"11:00 PM",
	"11:30 PM",
];