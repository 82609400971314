import React, { useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import PageHeading from "components/Typography/PageHeading";
import Card from "./components/Card";
import Graph from "./components/Graph";
import Banner from "./components/Banner";
import TotalSales from "./components/TotalSales";
import TraficSection from "./components/TraficSection";
import Select from "components/Select/Select";
import { TimeSelection } from "resources/constant";
import { parseDateFilter } from "utilifyFunctions";
import { txtCartFetch } from "utilifyFunctions";
import { getPreviousPeriodFilter } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";
import { getDateChartTick } from "utilifyFunctions";

export default function Analytics() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [dateRange, setDateRange] = useState("last_days_30");
	const [attributedRevenue, setAttributedRevenue] = useState("");
	const [previousAttributedRevenue, setPreviousAttributedRevenue] = useState(null);
	const [attributedRevDiff, setAttributedRevDiff] = useState(null);
	const [currentShopifyRevenue,setCurrentShopifyRevenue] = useState(null);
	const [previousShopifyRevenue, setPreviousShopifyRevenue] = useState(null);
	const [shopifyRevDiff,setShopifyRevDiff] = useState(null);
	const [smsResults, setSmsResults] = useState(null);
	const [smsResultsPrevious, setSmsResultsPrevious] =useState(null);
	const [sentDiff, setSentDiff] = useState(null);
	const [receivedDiff, setRecievedDiff] = useState(null);
	const [totalDiff, setTotalDiff] = useState(null);
	const [totalSalesData, setTotalSalesData] = useState([]);
	const [salesByCountry ,setSalesByCountry] = useState([]);
	const [messageByDate,setMessageByDate] = useState([]);
	const [recipientsByDate, setRecipientsByDate] = useState([]);
	const [cartRecoveryTotal, setCartRecoveryTotal] = useState(null);
	const [campaignTotal, setCampaignTotal] = useState(null);
	const [previousCampaignTotal, setPreviousCampaignTotal] = useState(null);
	const [previousACRTotal, setPreviousACRTotal] = useState(null);
	const [campaignDiff, setCampaignDiff]= useState(null);
	const [acrDiff, setAcrDiff] = useState(null);
	const [revenuePerRecipientData, setRevenuePerRecipientData] = useState([]);
	const [totalRevenuePerRecipient,setTotalRevenuePerRecipient] = useState(null);
	const [totalPreviousRecipients,setTotalPreviousRecipients] = useState(null);
	const [revenuePerDiff,setRevenuePerDiff] = useState(null);

	const handleDateChange = (label, value) => {
		setAcrDiff(null);
		setCampaignDiff(null);
		setAttributedRevDiff(null);
		setShopifyRevDiff(null);
		setSentDiff(null);
		setRecievedDiff(null);
		setTotalDiff(null);
		setRevenuePerDiff(null);
		setSmsResults(null);
		setCurrentShopifyRevenue(null);
		setAttributedRevenue(null);
		setTimeout(() => {
			setDateRange(value);
			loadMetrics(value);
		}, 500); 
	}

	const [showTutorialBanner, setShowTutorialBanner] = useState(true);


	async function getSalesByCountry(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/sales/country"+dateRange);
		setSalesByCountry(response);
	}

	async function getShopifyRevenueCurrent(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/revenue/shopify"+dateRange);
		setCurrentShopifyRevenue(response);
	}

	async function getShopifyRevenuePrevious(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/revenue/shopify"+dateRange);
		setPreviousShopifyRevenue(response?.totalRevenue);
	}

	async function getAttributedRevenueCurrent(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/revenue"+dateRange);
		setAttributedRevenue(response);
	}

	async function getAttributedRevenuePrevious(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/revenue"+dateRange);
		setPreviousCampaignTotal(response?.campaign);
		setPreviousACRTotal(response?.abandonedCart);
		setPreviousAttributedRevenue(response?.revenue);
	}

	async function getSMSCounts(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/messages/count"+dateRange);
		setSmsResults(response);
	}

	async function getSMSCountsPrevious(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/messages/count"+dateRange);
		setSmsResultsPrevious(response);
	}

	async function getRecipientsByDate(dateRange, filter){
		if(filter === 'all_time'){
			let localOldestCart = await getOldestCart();
			filter = checkAllTimeRange(localOldestCart);
			dateRange = parseDateFilter(filter, localOldestCart);
			dateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		}

		let URL = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/recipients/distinct"+dateRange
		if(dateRange){
			URL += "&dateFilter="+filter;
		}

		const response = await txtCartFetch("GET",URL);
		setRecipientsByDate(response);
	}

	async function getPreviousRecipientsByDateTotal(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN +  "/dashboard/metric/composite/recipients/distinct/total"+dateRange);
		console.log("total recipients");
		setTotalPreviousRecipients(response?.recipients);
	}

	async function calcRevenuePerRecipient(){
		let revenuePerRecipient = [];
		let sum = 0;
		let filter = dateRange; 
		if(dateRange === 'all_time'){
			let localOldestCart = await getOldestCart();
			filter = checkAllTimeRange(localOldestCart);
		}
		if(recipientsByDate?.length && totalSalesData?.length){
			let value; 
			for(let i = 0; i < recipientsByDate?.length; i++){
				if(recipientsByDate[i]?.recipients > 0){
					value = parseFloat(((totalSalesData[i]?.cartRecovery + totalSalesData[i]?.campaigns) / recipientsByDate[i]?.recipients).toFixed(2));
					revenuePerRecipient.push(
						{
							value: value,
							date: getDateChartTick(recipientsByDate[i]?.date, filter)
						});
				} else{
					value = parseFloat((totalSalesData[i]?.cartRecovery + totalSalesData[i]?.campaigns));
					revenuePerRecipient.push(
						{
							value: value,
							date: getDateChartTick(recipientsByDate[i]?.date,filter)
						});
				}
				sum += value; 
			}
			setTotalRevenuePerRecipient(sum);
		}
		setRevenuePerRecipientData([...revenuePerRecipient]);
	}

	useEffect(() => {
		calcRevenuePerRecipient();
	}, [recipientsByDate, totalSalesData]);

	useEffect(() => {
		if(totalRevenuePerRecipient != null && totalPreviousRecipients != null && previousAttributedRevenue != null){
			if(previousAttributedRevenue > 0 && totalPreviousRecipients > 0){
				setRevenuePerDiff((totalRevenuePerRecipient - (previousAttributedRevenue / totalPreviousRecipients) / totalRevenuePerRecipient));
			} else{
				setRevenuePerDiff(0);
			}
		}
	}, [totalRevenuePerRecipient, totalPreviousRecipients, previousAttributedRevenue]);


	async function getTotalSalesGraph(dateRange,filter){
		if(filter === 'all_time'){
			let localOldestCart = await getOldestCart();
			filter = checkAllTimeRange(localOldestCart);
			dateRange = parseDateFilter(filter, localOldestCart);
			dateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		}

		let URL = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/sales/total/graph"+dateRange
		if(dateRange){
			URL += "&dateFilter="+filter;
		}

		const response = await txtCartFetch("GET",URL);

		for(let i = 0; i < response?.dataPoints?.length; i++){
			response.dataPoints[i].date = getDateChartTick(response.dataPoints[i].date,filter);
		}
		setCartRecoveryTotal(response?.cartSum);
		setCampaignTotal(response?.campaignSum);
		setTotalSalesData(response?.dataPoints);
	}

	const getOldestCart = async function(){
		let oldestCart = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/oldest");
		return oldestCart;
	}

	const getMessagesByDate = async function(dateRange,filter){
		if(filter === 'all_time'){
			let localOldestCart = await getOldestCart();
			filter = checkAllTimeRange(localOldestCart);
			dateRange = parseDateFilter(filter, localOldestCart);
			dateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		}

		let URL = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/messages/date"+dateRange;

		if(dateRange){
			URL += "&dateFilter="+filter;
		}

		let response = await txtCartFetch("GET",URL);
		for(let i = 0; i < response?.length; i++){
			response[i].subject = getDateChartTick(response[i].date,filter,true);
		}
		setMessageByDate(response);
	}

	const checkAllTimeRange = function(oldestCart){
		let MS_IN_HOUR = 60 * 60 * 1000; 
		let MS_IN_DAY = 24 * MS_IN_HOUR;
		if(oldestCart){
			let today = new Date();
			let yesterday = addDays(today,-1);
			let oldestDate = new Date(oldestCart.lastMessage);

			let currentTime = Date.now();
			let diff = currentTime - oldestCart.lastMessage;

			if(today.getDate() === oldestDate.getDate() && today.getMonth() === oldestDate.getMonth() && today.getFullYear() === oldestDate.getFullYear()){
				return "today";
			}
			else if(yesterday.getDate() === oldestDate.getDate() && yesterday.getMonth() === oldestDate.getMonth() && yesterday.getFullYear() === oldestDate.getFullYear()){
				return "yesterday";
			}
			else if(diff < (7 * MS_IN_DAY)){
				return "last_days_7";
			}
			else if(diff < (30 * MS_IN_DAY)){
				return "last_days_30";
			}
			else{
				return "full";
			}
		} else{
			return("today");
		}
	}

	async function loadMetrics(filter){
		let dateRange = parseDateFilter(filter);
		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		getAttributedRevenueCurrent(urlDateRange);
		getShopifyRevenueCurrent(urlDateRange);
		getSMSCounts(urlDateRange);
		getTotalSalesGraph(urlDateRange,filter);
		getSalesByCountry(urlDateRange);
		getMessagesByDate(urlDateRange, filter);
		getRecipientsByDate(urlDateRange, filter);
		if(filter !== "all_time"){
			let previousFilter = getPreviousPeriodFilter(filter);
			let previousDateRange = parseDateFilter(previousFilter);
			let previousURLDateRange = (dateRange ? "?start="+previousDateRange.start+"&end="+previousDateRange.end : "");
			getAttributedRevenuePrevious(previousURLDateRange);
			getShopifyRevenuePrevious(previousURLDateRange);
			getSMSCountsPrevious(previousURLDateRange);
			getPreviousRecipientsByDateTotal(previousURLDateRange);
		} else{
			setPreviousAttributedRevenue(null);
			setPreviousShopifyRevenue(null);
			setAttributedRevDiff(0);
			setShopifyRevDiff(0);
			setSentDiff(0);
			setRecievedDiff(0);
			setTotalDiff(0);
			setAcrDiff(0);
			setCampaignDiff(0);
			setRevenuePerDiff(0);
		}
	}



	useEffect(() => {
		loadMetrics("last_days_30");
	}, []);

	useEffect(() => {
		if(attributedRevenue?.revenue != null && previousAttributedRevenue != null){
			setAttributedRevDiff(previousAttributedRevenue > 0 ? (((attributedRevenue.revenue - previousAttributedRevenue) / previousAttributedRevenue) * 100).toFixed(0) :  attributedRevenue.revenue > 0 ? 100 : 0);
			
		}
	}, [attributedRevenue, previousAttributedRevenue]);

	useEffect(() => {
		if(currentShopifyRevenue?.totalRevenue != null && previousShopifyRevenue != null){
			setShopifyRevDiff(previousShopifyRevenue > 0 ? (((currentShopifyRevenue.totalRevenue - previousShopifyRevenue) / previousShopifyRevenue) * 100).toFixed(0) :  currentShopifyRevenue.totalRevenue > 0 ? 100 : 0);
		
		}
	}, [currentShopifyRevenue, previousShopifyRevenue]);

	useEffect(() => {
		if(smsResults?.sent != null && smsResultsPrevious?.sent != null && dateRange !== "all_time"){
			setSentDiff(smsResultsPrevious.sent > 0 ? (((smsResults.sent - smsResultsPrevious.sent) / smsResultsPrevious.sent) * 100).toFixed(0) :  smsResults.sent > 0 ? 100 : 0);
			setRecievedDiff(smsResultsPrevious.received > 0 ? (((smsResults.received - smsResultsPrevious.received) / smsResultsPrevious.received) * 100).toFixed(0) :  smsResults.received > 0 ? 100 : 0);
			setTotalDiff(smsResultsPrevious.total > 0 ? (((smsResults.total - smsResultsPrevious.total) / smsResultsPrevious.total) * 100).toFixed(0) :  smsResults.total > 0 ? 100 : 0);
		} else{
			setSentDiff(0);
			setRecievedDiff(0);
			setTotalDiff(0);
		}
	}, [smsResults, smsResultsPrevious]);

	useEffect(() => {
		if(previousCampaignTotal != null && previousACRTotal != null && cartRecoveryTotal != null && campaignTotal != null){
			setCampaignDiff(previousCampaignTotal > 0 ? (((campaignTotal - previousCampaignTotal) / previousCampaignTotal) * 100).toFixed(0) : campaignTotal > 0 ? 100 : 0);
			setAcrDiff(previousACRTotal > 0 ? (((cartRecoveryTotal - previousACRTotal) / previousACRTotal) * 100).toFixed(0) : cartRecoveryTotal > 0 ? 100 : 0);
		} else{
			setAcrDiff(0);
			setCampaignDiff(0);
		}
	}, [previousCampaignTotal, previousACRTotal, cartRecoveryTotal, campaignTotal]);

	return (
		<Grid container alignItems={"center"}>
			{showTutorialBanner && 
				<Grid item xs={12}>
					<Banner mode={mode} closeTab={setShowTutorialBanner} />
				</Grid>
			}
			<Grid item xs={12} mt={"32px"} mb={'18px'}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={!isMobile ? "row" : "column"}
				>
				<PageHeading>Analytics</PageHeading>
					<Select
						options={TimeSelection}
						handleSelectChange={handleDateChange}
						defaultValue="Last 30 Days"
					>
					</Select>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box
					sx={{
						display: "flex",
						gap: "24px",
						flexDirection: isMobile ? "column" : "row",
					}}
					mb={"24px"}
				>
					<Card cardData={{title:"Total Revenue", value:currentShopifyRevenue?.totalRevenueText, isIncrease:shopifyRevDiff>=0, byValue: shopifyRevDiff?.toString().replace("-",""), valueType:"amount", desc:"The amount of revenue your brand has generated for the period across all sales channels"}}/>
					<Card cardData={{title:"Attributed Revenue", value:attributedRevenue?.revenueText?.replace("$",""), isIncrease:attributedRevDiff>=0, byValue: attributedRevDiff?.toString().replace("-",""), valueType:"amount", desc:"The amount of revenue generated from SMS"}}/>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: "24px",
						flexDirection: isMobile ? "column" : "row",
					}}
					mb={"24px"}
				>
					<Card cardData={{title:"SMS Sent", value:smsResults?.sentText, isIncrease:sentDiff>=0, byValue: sentDiff?.toString().replace("-",""), valueType:"number", desc:"The number of text messages sent to subscribers"}}/>
					<Card cardData={{title:"SMS Received", value:smsResults?.receivedText, isIncrease:receivedDiff>=0, byValue: receivedDiff?.toString().replace("-",""), valueType:"number", desc:"The number of text messages received by subscribers"}}/>
					<Card cardData={{title:"SMS Total", value:smsResults?.totalText, isIncrease:totalDiff>=0, byValue: totalDiff?.toString().replace("-",""), valueType:"number", desc:"The total number of SMS sent and received"}}/>
				</Box>
				<Box mb={"24px"}>
					<TotalSales data={totalSalesData}/>
				</Box>
				<Box mb={"24px"}>
					<TraficSection salesByCountry={salesByCountry} messageByDate={messageByDate}/>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: "24px",
						flexDirection: isMobile ? "column" : "row",
					}}
					mb={"24px"}
				>
					<Graph
						name="Revenue Per Recipient"
						value={totalRevenuePerRecipient ? "$" + totalRevenuePerRecipient?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "$0.00"}
						isIncrease={revenuePerDiff >= 0}
						byValue={revenuePerDiff?.toString().replace("-","")}
						data={revenuePerRecipientData}
						dataKey="value"
					/>
					<Graph
						name="Cart Recovery"
						value={cartRecoveryTotal ? "$" + cartRecoveryTotal?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "$0.00"}
						isIncrease={acrDiff >= 0}
						byValue={acrDiff?.toString().replace("-","")}
						data={totalSalesData}
						dataKey="cartRecovery"
					/>
					<Graph
						name="Campaigns"
						value={campaignTotal ? "$" + campaignTotal?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "$0.00"}
						isIncrease={campaignDiff >= 0}
						byValue={campaignDiff?.toString().replace("-","")}
						data={totalSalesData}
						dataKey="campaigns"
					/>
				</Box>
			</Grid>
		</Grid>
	);
}
