import {
	Box,
	Checkbox,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import DowngradeButton from "./Buttons/DowngradeButton";
import UpgradeButton from "./Buttons/UpgradeButton";
import RightArrow from "./RightArrow";
import { txtCartFetch } from "utilifyFunctions";
export default function ConfirmationStep({
	closeBillingModal,
	selected,
	period,
	currentPlan,
}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const [confirmed,setConfirmed] = React.useState(false);
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: belowTablet ? "90%" : "914px",
		bgcolor: theme?.palette?.card?.background,
		border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
		borderRadius: " 12px",
		boxShadow: 24,
		outline: "none",
		overflowY: "auto",
		p: 2,
	};

	const handleSubmit = async function(){
		console.log(confirmed);
		console.log(selected);
		console.log(period);
		if(confirmed){
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/billing/change?skuId="+selected.skuId + "&cycle="+period);
			if(response){
				window.location.replace(response.redirect_url);
			}
		}

	}

	return (
		<Box sx={style} maxHeight={"80%"}>
			<Box display={"flex"} justifyContent={"end"}>
				<img
					src="/icons/cut.svg"
					alt="close"
					onClick={closeBillingModal}
					id="pointerCursor"
				/>
			</Box>
			<Box display={"flex"} justifyContent={"center"}>
				<Typography
					color={theme.palette?.pageHeading}
					fontSize={"16px"}
					fontWeight={"600"}
				>
					{selected?.displayName} Plan
				</Typography>
			</Box>
			<Box display={"flex"} justifyContent={"center"} mb={"8px"}>
				<Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
					<Typography
						fontSize={"56px"}
						fontWeight={"700"}
						color={mode === "light" ? "#18113C" : "#FFF"}
						lineHeight={"normal"}
					>
					{period === "Monthly"
						? "$" + selected?.recurringFee
						: "$" + (selected?.annualRecurring / 12).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
					</Typography>
					<Typography
						fontSize={"14px"}
						color={mode === "light" ? "#696E7E" : "rgba(255, 255, 255, 0.50)"}
						fontWeight={"400"}
						sx={{
							opacity: "0.7",
						}}
					>
						/ month
					</Typography>
				</Box>
			</Box>
			<Box
				mb={3}
				display={"flex"}
				justifyContent={"center"}
				textAlign={"center"}
			>
				<Typography
					color={"#696E7E"}
					fontSize={"16px"}
					fontWeight={"400"}
					lineHeight={"normal"}
					sx={{
						maxWidth: "538px",
						opacity: "0.7",
					}}
				>
					{selected?.desc}
				</Typography>
			</Box>
			<Box display={"flex"} justifyContent={"center"}>
				<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
					width={"80%"}
				>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						// gap={"100px"}
						mb={2}
						position={"relative"}
					>
						<Box display={"flex"} width={"44%"}>
							<Typography
								fontSize={20}
								fontWeight={400}
								lineHeight={"26px"}
								sx={{
									color: "rgba(0, 13, 49, 0.50)",
								}}
							>
								Current:
							</Typography>
							<Typography
								fontSize={20}
								fontWeight={600}
								lineHeight={"26px"}
								ml={"5px"}
							>
								{currentPlan?.displayName} Plan
							</Typography>
						</Box>
						<Box width={"4%"}>
							<RightArrow />
						</Box>
						<Box display={"flex"} gap={"5px"} width={"48%"}>
							<Typography
								fontSize={20}
								fontWeight={400}
								lineHeight={"26px"}
								sx={{
									color: "rgba(0, 13, 49, 0.50)",
								}}
								ml={'70px'}
							>
								New:
							</Typography>
							<Typography fontSize={20} fontWeight={600} lineHeight={"26px"}>
								{selected?.displayName} Plan
							</Typography>
						</Box>
					</Box>
					<Comparison selected={selected} currentPlan={currentPlan} />
				</Box>
			</Box>

			<Box
				mb={"20px"}
				display={"flex"}
				justifyContent={"center"}
				textAlign={"center"}
				alignItems={"center"}
			>
				<Checkbox
					// defaultChecked
					sx={{
						color: "#969AA5",
						"&.Mui-checked": {
							color: "#0081FF",
						},
					}}
					onClick={() => setConfirmed(!confirmed)}
				/>
				<Typography
					color={"#696E7E"}
					fontSize={"16px"}
					fontWeight={"500"}
					lineHeight={"24px"}
					sx={{
						opacity: "0.7",
					}}
				>
					I understand and want to&nbsp;
					{checkUpgrade(selected, currentPlan) ? "Upgrade" : "Downgrade"}
				</Typography>
			</Box>
			<Box
				mb={"28px"}
				display={"flex"}
				justifyContent={"center"}
				textAlign={"center"}
			>
				{/* */}
				{checkUpgrade(selected, currentPlan) ? (
					<UpgradeButton
						onClick={handleSubmit}>
						Upgrade
					</UpgradeButton>
				) : (
					<DowngradeButton 
						onClick={handleSubmit}>
						Downgrade
					</DowngradeButton>
				)}
			</Box>
		</Box>
	);
}

const Comparison = ({ selected, currentPlan }) => {
	const theme = useTheme();
	var maxNumbersOfFeatures = [];
	let currentPlanFeatures = currentPlan?.keyFeatures;
	let selectedPlanFeatures = selected?.keyFeatures;
	const checkNumberOfFeature = () => {
		let selectedPlanFeaturesNumber = selectedPlanFeatures?.length;
		let currentPlanFeatureNumber = currentPlanFeatures?.length;
		let numbers = 0;
		if (selectedPlanFeaturesNumber > currentPlanFeatureNumber) {
			numbers = selectedPlanFeaturesNumber;
		} else {
			numbers = currentPlanFeatureNumber;
		}
		console.log(numbers);

		maxNumbersOfFeatures = new Array(numbers).fill(0);
	};
	checkNumberOfFeature();
	let upgrageFlag = checkUpgrade(selected, currentPlan);
	return (
		<>
			{maxNumbersOfFeatures?.map((item, index) => {
				return (
					<Box
						key={index}
						display={"flex"}
						justifyContent={"space-between"}
						// gap={"100px"}
						position={"relative"}
						mb={2}
					>
						<Box display={"flex"} width={"44%"}>
							<Typography
								color={theme.palette?.pageHeading}
								fontSize={20}
								fontWeight={500}
								lineHeight={"normal"}
							>
								{currentPlanFeatures[index]
									? currentPlanFeatures[index]
									: "Not Included"}
							</Typography>
						</Box>
						<Box width={"4%"}>
							<RightArrow />
						</Box>
						<Box display={"flex"} width={"48%"}>
							<Typography
								color={upgrageFlag ? "#0081FF" : "#FF004C"}
								fontSize={20}
								fontWeight={500}
								lineHeight={"normal"}
								ml={'70px'}
							>
								{selectedPlanFeatures[index]
									? selectedPlanFeatures[index]
									: "Not Included"}
							</Typography>
						</Box>
					</Box>
				);
			})}
		</>
	);
};

const checkUpgrade = (selected, currentPlan) => {
	if (selected?.tier >= currentPlan?.tier) {
		return true;
	}
	return false;
};
