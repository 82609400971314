/* eslint-disable no-unused-vars */
import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Conversation({ value,image,setSendMessageModal }) {
	const theme = useTheme();
	const belowMedium = useMediaQuery(theme.breakpoints.down("lg"));
	const [msg, setMsg] = useState("");
	useEffect(() => {
		const modifiedMessage = modifyMessage(value);
		setMsg(image?image+modifiedMessage:modifiedMessage);
	}, [value,image]);
	return (
		<>
			<Box position={belowMedium ? "static" : "fixed"}>
				<Box position={"relative"} display={"flex"} justifyContent={"center"}>
					<img src="/newMobile.png" alt="newMobile"/>
					<Box
						position={"absolute"}
						top={"60px"}
						sx={{
							background: "rgba(12, 132, 254, 0.10)",
							padding: "16px",
							borderRadius: "12px",
							maxWidth: "230px",
							minWidth: "230px",
							wordBreak: "break-word",
							width: "100%",
							maxHeight: "500px",
							overflowY:'scroll'
						}}
						className='mobile-phone'
					>
						<p
							style={{
								margin: "0",
								fontSize: "14px",
								fontWeight: "400",
								lineHeight: "20px",
								color: theme?.palette?.pageHeading,
							}}
							dangerouslySetInnerHTML={{ __html: msg }}
						></p>
					</Box>
				</Box>
				<Box display={"flex"} justifyContent={"center"} p={"0px 12px"} mt={'17px'}>
					<Button
						size="small"
						variant="outline"
						maxWidth="296px"
						fullWidth
						sx={{
							border: "1px solid #EBEBEB;",
							padding: "10px 30px",
							backgroundColor:theme?.palette.mode=='light'&&'#FFF'
							// width: isSmall ? "205px" : "296px",
							// maxWidth: isSmall ? "205px" : "296px",
						}}
						onClick={()=>setSendMessageModal(true)}
					>
						<img src="/icons/send.svg" alt="send" width={"15px"} />
						<Typography
							// fontSize={isSmall ? "10px" : "14px"}
							ml={2}
							fontWeight={600}
							// onClick={()=>navigate('/inbox')}
						>
							{" "}
							Send test message
						</Typography>
					</Button>
				</Box>
			</Box>
		</>
	);
}

function modifyMessage(message) {
	let modifiedMessage = message + "<br>Reply STOP to opt-out";
	modifiedMessage = modifiedMessage.replaceAll(/\n/g, "<br>");
	return modifiedMessage;
}