
const importSubscriberModalplatforms = [
	"Attentive",
	"Klaviyo",
	"Yotpo SMSBump",
	"Postscript",
	"Emotive",
	"Omnisend",
	"Liverecover",
	"Other",
];

const importSubscriberModalchannels = [
    "Shopify Checkout", 
    "Popup/Form", 
    "In Person"
];

const demoOption = [
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
	"lorem ipsum",
];

export {
    importSubscriberModalplatforms,
    importSubscriberModalchannels,
    demoOption,
};