import React, {useEffect, useState} from "react";
import {
	Grid,
	Typography,
	Box,
	useMediaQuery,
	useTheme,
	createTheme,
} from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import CircularProgress from "./Components/CircularProgressbar";
import OnboardingStep from "./Components/OnboardingStep";
import MoblieOnboardingStep from "./Components/MoblieOnboardingStep";
import { txtCartFetch } from "utilifyFunctions";
import { useNavigate } from "react-router-dom";
import { useIntercom } from 'react-use-intercom';
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function OnboardingSetup() {
	const navigate=useNavigate();
	const { showNewMessage } = useIntercom();
	const { showMessages } = useIntercom();
	const [steps, setSteps] = useState([]);
	const [stepsComplete, setStepsComplete] = useState("");
	const [completionPercent, setCompletionPercent] = useState(0);
	const [completeText, setCompleteText] = useState(0);
	const [displayOnboarding, setDisplayOnboarding] = useState(false);
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	const lightMode = mode === "light" ? true : false;
	const theme = createTheme({
		palette: {
			mode: mode,
		},
	});
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

	async function getOnboardingState(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/onboarding/state");
		setOnboarding(response);
	}

	function setOnboarding(response){
		if(response){
			setStepsComplete(response.complete)
			setCompleteText(response.completeText)
			setCompletionPercent(response.percent);
			setCompleteText(response.percentText);
			setDisplayOnboarding(response.displayOnboarding);
			setSteps(response.steps)
		}
	}

	function handleEnableClick(step,completed){
		console.log(step);
		if(!completed){
			if(step === "international"){
				Mixpanel.track_button_action(PAGES.HOME, BUTTONS.IPS_ENABLE);
				navigate("/settings?tab=messaging");
			} 
			else if(step === "persona"){
				Mixpanel.track_button_action(PAGES.HOME, BUTTONS.PERSONA_ADD);
				navigate("/settings?tab=company");
			}
			else if(step === "upload"){
				Mixpanel.track_button_action(PAGES.HOME, BUTTONS.SUS_UPLOAD);
				showMessages();
				showNewMessage("I would like to upload my subscribers.");
			}
		}
	}


	async function handleSkipClick(step){
		console.log(step);
		if(step === "international"){
			Mixpanel.track_button_action(PAGES.HOME, BUTTONS.IPS_SKIP);
		} 
		else if(step === "persona"){
			Mixpanel.track_button_action(PAGES.HOME, BUTTONS.PERSONA_SKIP);
		}
		else if(step === "upload"){
			Mixpanel.track_button_action(PAGES.HOME, BUTTONS.SUS_SKIP);
		}
		const updateResponse = await txtCartFetch("PUT", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/onboarding/step/skip/"+step);
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/onboarding/state");
		setOnboarding(response);
	}


	useEffect(() => {
		getOnboardingState();
		return;
	}, []);

	return displayOnboarding ? (
		<>
			<Box
				sx={{
					border: `1px solid ${getMode.palette?.card?.border}`,
					borderRadius: "12px",
					background: getMode.palette?.card?.background,
				}}
			>
				<Grid container direction="row">
					<Grid
						container
						item
						sm={2.33}
						borderRight={lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D"}
						justifyContent={"center"}
					>
						<Box width={"100%"} padding={"1rem 0"}>
							<Typography fontSize={"16px"} fontWeight={"600"} align={"center"}>
								Finish setting up
							</Typography>
							<CircularProgress mode={mode} isLargeScreen={isLargeScreen} value={completionPercent} text={completeText}/>
							<Typography fontSize={"14px"} fontWeight={"500"} align={"center"}>
								{stepsComplete}
							</Typography>
						</Box>
					</Grid>
					<Grid
						container
						item
						sm={9.67}
						display={{ xs: "none", sm: "flex" }}
						justifyContent={"space-between"}
					>
						{steps?.map((item, index) => {
							return (
								<OnboardingStep
									key={index}
									index={index}
									mode={mode}
									isLargeScreen={isLargeScreen}
									text={item.instruction}
									isCompleted={item.complete}
									handleEnableClick={handleEnableClick}
									handleSkipClick={handleSkipClick}
									step={item.step}
									buttonTitle={item.buttonTitle}
								/>
							);
						})}
					</Grid>

					<Grid
						container
						item
						sm={9.67}
						display={{ xs: "flex", sm: "none" }}
						justifyContent={"space-between"}
					>
						{steps?.map((item, index) => {
							return (
								<MoblieOnboardingStep
									key={index}
									index={index}
									mode={mode}
									isLargeScreen={isLargeScreen}
									text={item.instruction}
									isCompleted={item.complete}
									handleEnableClick={handleEnableClick}
									handleSkipClick={handleSkipClick}
									step={item.step}
									buttonTitle={item.buttonTitle}
								/>
							);
						})}
					</Grid>
				</Grid>
			</Box>
		</>
	) : (<></>);
}
