import {  Grid } from "@mui/material";
import OnboardingSetup from "./components/OnboardingSetup";
import KeyMetrics from "./components/Metrics";
import RecommendedSteps from "./components/RecommendedSetps";
import React,{ useEffect }  from "react";
import { useIntercom } from 'react-use-intercom';
import { txtCartFetch } from "utilifyFunctions";
import { Mixpanel } from '../../utils/mixpanel';
import { PAGES, EVENT_CATEGORIES } from '../../constants/mixPanelEvents';

export default function Dashboard() {
	const { boot } = useIntercom();

	async function getUser() {
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/user");
		if(response){
			boot({ 
				name: response.cust_name,
				email: response.email,
				userHash: response.intercomMac,
			})
			
			console.log('intercom booted');
			console.log(response);
			// set shopId & shopifyDomain as localStorage attribute
			localStorage.setItem("shopId", response.shop_id);
			localStorage.setItem("MyShopifyDomain", response.shop_domain);
			localStorage.setItem("storeName", response.shopName);
			localStorage.setItem("storeUrl", response.shopUrl);
			Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
				page: PAGES.HOME,
			});
		}
    	return;
	}
	
	useEffect(() => {
		getUser();
		return;
	}, []);
	  

	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<OnboardingSetup />
			</Grid>
			<Grid item xs={12}>
				<KeyMetrics />
			</Grid>
			<Grid item xs={12}>
				<RecommendedSteps />
			</Grid>
		</Grid>
	);
}
