import {
	Box,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useMediaQuery,
	useTheme,
	Skeleton,
	Divider
} from "@mui/material";
import { Dropdown, Input } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { SearchSVG, FilterSVG } from "resources/Icons";
import Currency from "resources/Icons/Currency";
import { useDebounce } from "@uidotdev/usehooks"
import { formatPhoneNumber, formatTimeFromHours, truncateMessage, ContactTabs, ContactFilterOptions } from '../components/commons';
import { txtCartFetch } from "utilifyFunctions";

export default function Contacts({ activeTab, isActive, setIsActive, contactsData, setContactsData, conversationFilteredCount, setConversationFilteredCount, bodyMarginTop }) {
	const MemoizedChatCard = React.memo(ChatCard);
	const theme = useTheme();
	const [tab, setTab] = useState(ContactTabs.CartRecovery);
	const [filterOptions, setfilterOptions] = useState(null);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [campaignTabIndex, setCampaignTabIndex] = useState(0);
	const [cartRecoveryTabIndex, setCartRecoveryTabIndex] = useState(0);
	const [filteredContacts, setFilteredContacts] = useState([]); 
	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 300);
	const [loading, setLoading] = useState(false);
	const scrollContainerRef = useRef();
	let fetchLimit = 50;

	// List of contacts
	const [searchedContacts, setSearchedContacts] = useState([])

	const [cartRecoveryOpenContacts, setCartRecoveryOpenContacts] = useState([]);
	const [cartRecoveryClosedContacts, setCartRecoveryClosedContacts] = useState([]);
	const [cartRecoveryOverriddenContacts, setCartRecoveryOverriddenContacts] = useState([]);
	const [cartRecoveryRecoveredContacts, setCartRecoveryRecoveredContacts] = useState([]);
	const [cartRecoveryContacts, setCartRecoveryContacts] = useState([])

	const [campaignOpenContacts, setCampaignOpenContacts] = useState([]);
	const [campaignClosedContacts, setCampaignClosedContacts] = useState([]);
	const [campaignOverriddenContacts, setCampaignOverriddenContacts] = useState([]);
	const [campaignRecoveredContacts, setCampaignRecoveredContacts] = useState([]);
	const [campaignContacts, setCampaignContacts] = useState([])

	// offsets management
	const [cartRecoveryOffset, setCartRecoveryOffset] = useState(fetchLimit);
	const [cartRecoveryOpenOffset, setCartRecoveryOpenOffset] = useState(0);
	const [cartRecoveryClosedOffset, setCartRecoveryClosedOffset] = useState(0);
	const [cartRecoveryOverriddenOffset, setCartRecoveryOverriddenOffset] = useState(0);
	const [cartRecoveryRecoveredOffset, setCartRecoveryRecoveredOffset] = useState(0);
	
	const [campaignOffset, setCampaignOffset] = useState(fetchLimit);
	const [campaignOpenOffset, setCampaignOpenOffset] = useState(0);
	const [campaignClosedOffset, setCampaignClosedOffset] = useState(0);
	const [campaignOverriddenOffset, setCampaignOverriddenOffset] = useState(0);
	const [campaignRecoveredOffset, setCampaignRecoveredOffset] = useState(0);

	// Manage shouldScrollLoad objects with useState
	const [shouldScrollLoadCartTab, setShouldScrollLoadCartTab] = useState({
		all: true,
		Open: true,
		Closed: true,
		Overridden: true,
		Recovered: true,
	});

	const [shouldScrollLoadCampaignTab, setShouldScrollLoadCampaignTab] = useState({
		all: true,
		Open: true,
		Closed: true,
		Overridden: true,
		Recovered: true,
	});

	useEffect(() => {
		let cartOpen = contactsData.filter((contact) => !contact.closed && contact.type === "cart");
		let cartClosed = contactsData.filter((contact) => contact.closed && contact.type === "cart");
		let cartOverridden = contactsData.filter((contact) => contact.override && contact.type === "cart");
		let cartRecovered = contactsData.filter((contact) => contact.isRecovered && contact.type === "cart");
		let cartRecoveryContacts = contactsData.filter((contact) => contact.type === "cart");

		let campaignOpen = contactsData.filter((contact) => contact.status === "open" && contact.type === "campaign");
		let campaignClosed = contactsData.filter((contact) => contact.status === "closed" && contact.type === "campaign");
		let campaignOverridden = contactsData.filter((contact) => contact.override && contact.type === "campaign");
		let campaignRecovered = contactsData.filter((contact) => contact.isRecovered && contact.type === "campaign");
		let campaignContacts = contactsData.filter((contact) => contact.type === "campaign");

		setCartRecoveryOpenContacts(cartOpen);
		setCartRecoveryClosedContacts(cartClosed);
		setCartRecoveryOverriddenContacts(cartOverridden);
		setCartRecoveryRecoveredContacts(cartRecovered);
		setCartRecoveryContacts(cartRecoveryContacts);

		setCartRecoveryOpenOffset(cartOpen.length);
		setCartRecoveryClosedOffset(cartClosed.length);
		setCartRecoveryOverriddenOffset(cartOverridden.length);
		setCartRecoveryRecoveredOffset(cartRecovered.length);
		setCartRecoveryOffset(cartRecoveryContacts.length);

		setCampaignOpenContacts(campaignOpen);
		setCampaignClosedContacts(campaignClosed);
		setCampaignOverriddenContacts(campaignOverridden);
		setCampaignRecoveredContacts(campaignRecovered);
		setCampaignContacts(campaignContacts);

		setCampaignOffset(campaignContacts.length);
		setCampaignClosedOffset(campaignClosed.length);
		setCampaignOpenOffset(campaignOpen.length);
		setCampaignOverriddenOffset(campaignOverridden.length);
		setCampaignRecoveredOffset(campaignRecovered.length);

		if (cartRecoveryContacts.length === 0 && campaignContacts.length > 0) {
			setTab(ContactTabs.Campaign);
		}
	}, [contactsData]);

	const getOffsetByFilter = (filter, isCartRecovery) => {
		if (isCartRecovery) {
			switch (filter) {
				case ContactFilterOptions.Open:
					return cartRecoveryOpenOffset;
				case ContactFilterOptions.Closed:
					return cartRecoveryClosedOffset;
				case ContactFilterOptions.Overridden:
					return cartRecoveryOverriddenOffset;
				case ContactFilterOptions.Recovered:
					return cartRecoveryRecoveredOffset;
				default:
					return cartRecoveryOffset;
			}
		} else {
			switch (filter) {
				case ContactFilterOptions.Open:
					return campaignOpenOffset;
				case ContactFilterOptions.Closed:
					return campaignClosedOffset;
				case ContactFilterOptions.Overridden:
					return campaignOverriddenOffset;
				case ContactFilterOptions.Recovered:
					return campaignRecoveredOffset;
				default:
					return campaignOffset;
			}
		}
	};

	/**
	 * Fetches more conversations from the backend based on the current tab and filter options
	 * @param {number} limit - The number of conversations to fetch
	 */
	const fetchMoreConversations = async (limit = fetchLimit) => {
		setLoading(true);
		try {
			const isCartRecovery = tab === ContactTabs.CartRecovery;
			const shouldScrollLoad = isCartRecovery ? shouldScrollLoadCartTab : shouldScrollLoadCampaignTab;
			let offset;
			if (isCartRecovery) {
				if (filterOptions) {
					offset = getOffsetByFilter(filterOptions, isCartRecovery);
				} else {
					offset = cartRecoveryOffset;
				}
			} else {
				if (filterOptions) {
					offset = getOffsetByFilter(filterOptions, isCartRecovery);
				} else {
					offset = campaignOffset;
				}
			}
			await new Promise((resolve) => setTimeout(resolve, 1500));
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + `/dashboard/inbox/conversations?type=${tab=== ContactTabs.CartRecovery ? "cart" : "campaign"}&limit=${limit}&offset=${offset}&filter=${filterOptions}`);
			let fetchedConversationCount = response.length;
			if(fetchedConversationCount === 0) {
				const updatedShouldScrollLoad = { ...shouldScrollLoad };
				if (filterOptions) {
					updatedShouldScrollLoad[filterOptions] = false;
				} else {
					updatedShouldScrollLoad.all = false;
				}
				isCartRecovery
					? setShouldScrollLoadCartTab(updatedShouldScrollLoad)
					: setShouldScrollLoadCampaignTab(updatedShouldScrollLoad);
			}
			if(tab === ContactTabs.CartRecovery){
				if(filterOptions){
					if(filterOptions === ContactFilterOptions.Open){
						setCartRecoveryOpenOffset(cartRecoveryOpenOffset + fetchedConversationCount);
						setCartRecoveryOpenContacts([...cartRecoveryOpenContacts, ...response]);
					}
					if(filterOptions === ContactFilterOptions.Closed){
						setCartRecoveryClosedOffset(cartRecoveryClosedOffset + fetchedConversationCount);
						setCartRecoveryClosedContacts([...cartRecoveryClosedContacts, ...response]);
					}
					if(filterOptions === ContactFilterOptions.Overridden){
						setCartRecoveryOverriddenOffset(cartRecoveryOverriddenOffset + fetchedConversationCount);
						setCartRecoveryOverriddenContacts([...cartRecoveryOverriddenContacts, ...response]);
					}
					if(filterOptions === ContactFilterOptions.Recovered){
						setCartRecoveryRecoveredOffset(cartRecoveryRecoveredOffset + fetchedConversationCount);
						setCartRecoveryRecoveredContacts([...cartRecoveryRecoveredContacts, ...response]);
					}
				}else{
					setCartRecoveryOffset(cartRecoveryOffset + fetchedConversationCount);
				}
			} else {
				if(filterOptions){
					if(filterOptions === ContactFilterOptions.Open) setCampaignOpenOffset(campaignOpenOffset + fetchedConversationCount);
					if(filterOptions === ContactFilterOptions.Closed) setCampaignClosedOffset(campaignClosedOffset + fetchedConversationCount);
					if(filterOptions === ContactFilterOptions.Overridden) setCampaignOverriddenOffset(campaignOverriddenOffset + fetchedConversationCount);
					if(filterOptions === ContactFilterOptions.Recovered) setCampaignRecoveredOffset(campaignRecoveredOffset + fetchedConversationCount);
				}else{
					setCampaignOffset(campaignOffset + fetchedConversationCount);
				}
			}
		} catch (error) {
			console.error("Failed to fetch conversations", error);
		} finally {
			setLoading(false);
		}
    };

	const handleScroll = useCallback(() => {
		// Determine which tab and filter to check
		const shouldScrollLoad = tab === ContactTabs.CartRecovery 
			? shouldScrollLoadCartTab 
			: shouldScrollLoadCampaignTab;
	
		if (filterOptions ? !shouldScrollLoad[filterOptions] : !shouldScrollLoad.all) {
			return; // Stop if scroll loading is disabled for the current filter
		}
	
		const container = scrollContainerRef.current;
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 150 && !loading) {
			fetchMoreConversations(fetchLimit);
		}
	}, [tab, filterOptions, shouldScrollLoadCartTab, shouldScrollLoadCampaignTab, loading, fetchMoreConversations]);	

	const filterContacts = useCallback(() => {
		if(isSearching) return searchedContacts;
		if (tab === ContactTabs.CartRecovery) {
			switch (filterOptions) {
				case ContactFilterOptions.Open:
					return cartRecoveryOpenContacts;
				case ContactFilterOptions.Closed:
					return cartRecoveryClosedContacts;
				case ContactFilterOptions.Overridden:
					return cartRecoveryOverriddenContacts;
				case ContactFilterOptions.Recovered:
					return cartRecoveryRecoveredContacts;
				default:
					return cartRecoveryContacts;
			}
		} else {
			switch (filterOptions) {
				case ContactFilterOptions.Open:
					return campaignOpenContacts;
				case ContactFilterOptions.Closed:
					return campaignClosedContacts;
				case ContactFilterOptions.Overridden:
					return campaignOverriddenContacts;
				case ContactFilterOptions.Recovered:
					return campaignRecoveredContacts;
				default:
					return campaignContacts;
			}
		}
	}, [
		tab,
		filterOptions,
		cartRecoveryOpenContacts,
		cartRecoveryClosedContacts,
		cartRecoveryOverriddenContacts,
		cartRecoveryRecoveredContacts,
		campaignOpenContacts,
		campaignClosedContacts,
		campaignOverriddenContacts,
		campaignRecoveredContacts,
		campaignContacts,
		cartRecoveryContacts,
		isSearching,
		searchedContacts,
	]);	

	useEffect(() => {
		const fetchSearchResults = async (query) => {
			setLoading(true);
			setIsSearching(true);
			try {
				const response = await txtCartFetch(
					"GET",
					process.env.REACT_APP_BACKEND_DOMAIN +
						`/dashboard/inbox/conversation/search?query=${encodeURIComponent(query)}&type=${tab === ContactTabs.CartRecovery ? "cart" : "campaign"}`
				);
				setSearchedContacts(response);
			} catch (error) {
				console.error("Failed to fetch search results", error);
			} finally {
				setLoading(false);
			}
		};
	
		if (debouncedSearchQuery && debouncedSearchQuery.length >= 3) {
			fetchSearchResults(debouncedSearchQuery);
		} else {
			setIsSearching(false);
			setSearchedContacts([]);
		}
	}, [debouncedSearchQuery, tab]);

	const handleContactClick = (item, index) => {
		let { id } = item;
        if (tab === ContactTabs.CartRecovery) {
            setCartRecoveryTabIndex(index);
        } else {
            setCampaignTabIndex(index);
        }
		if(item.id !== isActive?.id){
			setIsActive({ ...item, index, count: 0 });
		}
		contactsData.map((contact) => {
			if (contact.id === id) {
				contact.count = 0;
			}
			return contact;
		})
    };


	useEffect(() => {
		const finalFilteredContacts = filterContacts();
		setFilteredContacts(finalFilteredContacts);

		if (!isActive || !finalFilteredContacts.some(contact => contact.id === isActive.id)) {
			if (finalFilteredContacts.length > 0) {
				if (tab === ContactTabs.CartRecovery) {
					if (cartRecoveryTabIndex >= finalFilteredContacts.length) {
						setCartRecoveryTabIndex(0);
					}
					setIsActive({ ...finalFilteredContacts[cartRecoveryTabIndex], index: cartRecoveryTabIndex });
				} else {
					if (campaignTabIndex >= finalFilteredContacts.length) {
						setCampaignTabIndex(0);
					}
					setIsActive({ ...finalFilteredContacts[campaignTabIndex], index: campaignTabIndex });
				}
			}
		}
	}, [tab, filterOptions, contactsData, cartRecoveryTabIndex, campaignTabIndex, isActive, setIsActive, filterContacts, searchedContacts]);

	useEffect(() => {
		const container = scrollContainerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (container) {
				container.removeEventListener("scroll", handleScroll);
			}
		};
	}, [loading]);

	return (
		<Box
		width={isMobile ? "100%" : "25%"}
		borderRight={`1px solid ${theme?.palette?.card?.border}`}
		display={isMobile ? (activeTab === "Info" ? "block" : "none") : "block"}
		bgcolor={theme?.palette?.card?.background}
		// mt={`${bodyMarginTop}px`}
		// bottom={`${bodyMarginTop}px`}
		// position={bodyMarginTop? "relative": "unset"}
		>
		<Box p={"20px  0px 0px 0px"}>
			{/* Tab selection for switching between "Cart Recovery" and "Promotional Campaigns" */}
			<TabSelection
			tab={tab}
			setTab={setTab}
			filterOption={filterOptions}
			setfilterOption={setfilterOptions}
			conversationFilteredCount={conversationFilteredCount}
			setConversationFilteredCount={setConversationFilteredCount}
			/>

			<Box mb={"31px"} p={"0px 13px"}>
			<Input
				placeholder="Search by Phone or First Name"
				prefix={<SearchSVG />}
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
			/>
			</Box>

			<Box
			sx={{
				overflow: "hidden",
				overflowY: "scroll",
				minHeight: `calc(100vh - ${isMobile ? "235px" : `(176px + ${bodyMarginTop}px)`})`,
				maxHeight: `calc(100vh - ${isMobile ? "235px" : `(176px + ${bodyMarginTop}px)`})`,
			}}
			ref={scrollContainerRef}
			className="no-scrollbar"
			>
			{/* Display filtered contacts */}
			{filteredContacts.length !== 0 ? filteredContacts.map((item, index) => (
				<MemoizedChatCard
					key={index}
					name={item?.name}
					count={item?.count}
					number={item?.number}
					time={item?.time}
					message={item?.message}
					isActive={isActive?.index === index}
					index={index}
					isRecovered={item?.isRecovered}
					onClick={() => handleContactClick(item, index)}
				/>
				))
			: <SecondaryText marginLeft={"13px"}>
				No contacts found
			</SecondaryText>}
			{/* Skeleton loading indicator */}
			{loading &&
			[...Array(Math.floor(Math.random() * (10 - 5 + 1)) + 5)].map((_, idx) => (
				<Box key={idx}>
				<Box p='20px' display='flex' gap='6px' flexDirection='column'>
					<SkeletonText width='102px' />
					<SkeletonText width='118px' />
					<Box display='flex' justifyContent='space-between'>
					<SkeletonText width='200px' />
					<SkeletonText width='22px' />
					</Box>
				</Box>
				<Divider />
				</Box>
			))}
			</Box>
		</Box>
		</Box>
	);
}

export const SkeletonText = ({ width }) => (
	<Skeleton
		variant='text'
		animation='wave'
		sx={{
		fontSize: "1rem",
		bgcolor: "rgba(0, 13, 49, 0.01) !important",
		"&::before": {
			backgroundColor: "rgba(0, 13, 49, 0.01) !important",
		},
		"&::after": {
			backgroundColor: "rgba(0, 13, 49, 0.01) !important",
		},
		}}
		height='10px'
		width={width}
	/>
);

const ChatCard = ({
	number,
	count,
	name,
	message,
	time,
	isActive,
	isRecovered,
	...props
}) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;

	return (
		<Box
			display={"flex"}
			width={"100%"}
			sx={{
				background: isActive
					? lightMode
						? "#E7F3FF"
						: "rgba(12, 132, 254, 0.10)"
					: "transparent",
				":hover": {
					background: lightMode
						? "rgba(0, 0, 0, 0.04)"
						: "rgba(255, 255, 255, 0.08)",
				},
			}}
		>
			<Box
				sx={{
					visibility: isActive ? "visible" : "hidden",
				}}
			>
				<div className="card-bar">&nbsp;</div>
			</Box>
			<Box
				className="contact-card"
				width={"100%"}
				// display={"flex"}
				p={"13px 0px"}
				id="pointerCursor"
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
				{...props}
			>
				<Box p={"0 13px"}>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						mb={"7px"}
						sx={{}}
					>
						<Box>
							<Typography
								color={"#808698"}
								fontSize={12}
								fontWeight={600}
								lineHeight={"16px"}
							>
								{formatPhoneNumber(number)}
							</Typography>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems={"flex-start"}
						gap={isRecovered ? "7px" : "0px"}
					>
						{isRecovered && <Currency isActive={isActive} />}
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							width={"100%"}
						>
							<Typography
								color={theme?.palette?.pageHeading}
								fontSize={16}
								fontWeight={600}
								lineHeight={"normal"}
								sx={{
									opacity: isActive ? 1 : 0.5,
								}}
							>
								{name}
							</Typography>
							{count>0 &&  (
								<Box
									sx={{
										width: "24px",
										height: "21px",
										background: "#FF004C",
										borderRadius: "100px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										padding: "2px 6px",
									}}
								>
									<Typography
										color={"#FFF"}
										fontSize={12}
										fontWeight={700}
										lineHeight={"normal"}
									>
										{count}
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
					<Box display={"flex"} justifyContent={"space-between"} mt={"8px"}>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={400}
							lineHeight={"16px"}
						>
							{truncateMessage(message, 50)}
						</Typography>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={400}
							lineHeight={"16px"}
						>
							{formatTimeFromHours(time)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const TabSelection = ({ tab, setTab, setfilterOption, filterOption, conversationFilteredCount, setConversationFilteredCount }) => {
	const theme = useTheme();
	return (
		<Box p={"0px 13px"} display={"flex"} justifyContent={"space-between"}>
			<Box display={"flex"} gap={"12px"} mb={"16px"}>
				<Typography
					id="pointerCursor"
					color={tab === ContactTabs.CartRecovery ? theme?.palette?.pageHeading : "#808698"}
					fontSize={16}
					fontWeight={tab === ContactTabs.CartRecovery ? 600 : 500}
					lineHeight={"normal"}
					onClick={() => setTab(ContactTabs.CartRecovery)}
					pb={"7px"}
					sx={{
						borderBottom:
							tab === ContactTabs.CartRecovery
								? `2px solid #0C84FE`
								: `2px solid ${theme?.palette?.card?.background}`,
					}}
				>
					Cart Recovery
				</Typography>
				<Typography
					id="pointerCursor"
					color={tab === ContactTabs.Campaign ? theme?.palette?.pageHeading : "#808698"}
					fontSize={16}
					fontWeight={tab === ContactTabs.Campaign ? 600 : 500}
					lineHeight={"normal"}
					pb={"7px"}
					onClick={() => setTab(ContactTabs.Campaign)}
					sx={{
						borderBottom:
							tab === ContactTabs.Campaign
								? `2px solid #0C84FE`
								: `2px solid ${theme?.palette?.card?.background}`,
					}}
				>
					Campaign
				</Typography>
			</Box>
			<Box>
				<Dropdown
					overlay={
						<ActionMenu
							setfilterOption={setfilterOption}
							filterOption={filterOption}
							conversationFilteredCount={conversationFilteredCount}
							setConversationFilteredCount={setConversationFilteredCount}
						/>
					}
					placement="bottomRight"
					trigger={["click"]}
				>
					<Box id="pointerCursor">
						<FilterSVG />
						{filterOption && (
							<Box
								sx={{
									position: "relative",
									zIndex: 9999999,
									top: "-20px",
									right: "-25px",
									width: "8px",
									height: "8px",
									borderRadius: "50%",
									marginTop: "-8px",
								}}
							>
								<Box className="notification-dot" sx={{ top: "0px" }}>&nbsp;</Box>
							</Box>
						)}
					</Box>
				</Dropdown>
			</Box>
		</Box>
	);
};

const SecondaryText = ({ children, ...props }) => {
	return (
		<Typography
			fontWeight={500}
			fontSize={14}
			color={"#808698"}
			{...props}
			lineHeight={"normal"}
		>
			{children}
		</Typography>
	);
};

const ActionMenu = ({ setfilterOption, filterOption, conversationFilteredCount, setConversationFilteredCount }) => {
	const theme = useTheme();

	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"4px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem
					key={"1"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						filterOption === ContactFilterOptions.Overridden ? setfilterOption(null) : setfilterOption(ContactFilterOptions.Overridden);
					}}
				>
					<div
						style={{
							visibility: filterOption === ContactFilterOptions.Overridden ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" alt="tick" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption === ContactFilterOptions.Overridden && "#0081FF"}
						>
							<SecondaryText>Overridden ({conversationFilteredCount.overridden})</SecondaryText>
						</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem
					key={"2"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						filterOption === ContactFilterOptions.Open ? setfilterOption(null) : setfilterOption(ContactFilterOptions.Open);
					}}
				>
					<div
						style={{
							visibility: filterOption === ContactFilterOptions.Open ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" alt="tick" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption === ContactFilterOptions.Open && "#0081FF"}
						>
							<SecondaryText>Open ({conversationFilteredCount.open})</SecondaryText>
						</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem
					key={"3"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						filterOption === ContactFilterOptions.Closed ? setfilterOption(null) : setfilterOption(ContactFilterOptions.Closed);
					}}
				>
					<div
						style={{
							visibility: filterOption === ContactFilterOptions.Closed ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" alt="tick" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption === ContactFilterOptions.Closed && "#0081FF"}
						>
							<SecondaryText>Closed ({conversationFilteredCount.closed})</SecondaryText>
						</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem
					key={"4"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						filterOption === ContactFilterOptions.Recovered ? setfilterOption(null) : setfilterOption(ContactFilterOptions.Recovered);
					}}
				>
					<div
						style={{
							visibility:
								filterOption === ContactFilterOptions.Recovered ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" alt="tick" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption === ContactFilterOptions.Recovered && "#0081FF"}
						>
							<SecondaryText>Recovered ({conversationFilteredCount.recovered})</SecondaryText>
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode === "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
			ml={"8px"}
		>
			{children}
		</Typography>
	);
};
